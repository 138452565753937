import "./WriteAnswers.css";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { Idirection } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import { PlayFunction } from "use-sound/dist/types";
import {
  Arrows,
  Background,
  ConfirmButton,
  ExerciseImage,
  HelpMenu,
  Professor,
  TextBox,
  ExerciseName,
} from "./_components";

// Simple hashing function
const stringToHashConversion = (string: string) => {
  var hashVal = 0;
  if (string.length == 0) return hashVal;
  for (let i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hashVal = (hashVal << 5) - hashVal + char;
    hashVal = hashVal & hashVal;
  }
  return hashVal;
};

// Component
const WriteAnswers = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const mainState = useAppSelector((state) => state.main);
  const commonX = mainState.x;

  const hash = stringToHashConversion(props.exercise.text || "");

  var randomLims = props.exercise.random_lims;
  if (randomLims == null) {
    randomLims = [1, 10];
  }

  const x = Math.round(
    randomLims[0] +
      (randomLims[1] - randomLims[0]) *
        parseFloat(((commonX * Math.abs(hash)) % 1).toFixed(1))
  ); // CRYPTO TECH GOING ON

  // CHECKING IF DISTRIBUTION IS UNIFORM
  // let x_vals = [];
  // let comx = 0;
  // let ex = 0;
  // for(let i = 0; i<1000; i++){
  //   comx = Math.random()
  //   ex = Math.round((props.randomLims[0] + (props.randomLims[1]-props.randomLims[0])*(comx*Math.abs(hash) % 1))*100)/100   // CRYPTO TECH GOING ON
  //   x_vals.push(ex)
  // }

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const image = props.exercise.image?.url;
  const animationUrl = props.exercise.animation?.url;

  const labels = props.exercise.labels as string[];
  const options = props.exercise.options as string[];

  const [answers, setAnswers] = useState<string[]>([]);
  const [maxError] = useState(props.exercise.max_error as number[]);
  const [selectedExercise, setSelectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setMaxExercise] = useState(props.maxExercise);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [incorrectIndexes, setIncorrectIndexes] = useState<number[]>([]);

  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    var newAnswers = _.fill(Array(options.length), "");

    if (props.research) {
      if (selectedExercise < maxExercise) {
        newAnswers = options;
      }
    }
    setAnswers(newAnswers);
  }, [labels, selectedExercise, maxExercise]);

  useEffect(() => {
    setSelectedExercise(props.exerciseIndex);
    setMaxExercise(props.maxExercise);
  }, [props.exerciseIndex]);

  const confirmHandler = () => {
    var correct = true;
    let wrongIndexes = [];

    for (var i = 0; i < options.length; i++) {
      try {
        var ans = eval(answers[i]);
        var sol = eval(options[i]); // TODO - WTH is this xDDDDD
      } catch {
        ans = NaN;
        sol = NaN;
      }
      console.log(ans, sol, maxError[i] / 100);
      //var sol = eval("x**2")

      if (!isNaN(sol) && !isNaN(ans) && maxError != null) {
        //numeric answer AND solution
        if (Math.abs(ans - sol) > maxError[i] / 10) {
          correct = false;
        }
      } else {
        //something isn't numeric
        ans = answers[i]
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s/g, "") // remove spaces
          .toLowerCase();
        sol = options[i]
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s/g, "") // remove spaces
          .toLowerCase();
        if (ans != sol) {
          correct = false;
          wrongIndexes.push(i);
        }
      }
    }
    if (props.research) {
      if (correct) {
        props.updateLevel("right", answers, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
        setIncorrectIndexes(wrongIndexes);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers, true);
      } else {
        props.updateLevel("right", answers, false);
      }
    }
  };

  useEffect(() => {
    if (image !== undefined || animationUrl !== undefined) {
      setImageExists(true);
    }
  }, [image, animationUrl]);

  const renderWriteBoxes = () => {
    return (
      <div
        className="write_answers_write_boxes"
        style={{
          gridTemplateColumns:
            "repeat(" + labels.length + ", " + 100 / labels.length + "%)",
        }}
      >
        {labels.map((lb: any, labelIndex: any) => {
          var label = lb;
          if (lb == null) {
            label = "";
          }
          var left = 90 - label.length * 1;
          var right = label.length * 1 + 5;
          return (
            <div
              key={labelIndex}
              className={
                incorrectFeedback === true
                  ? incorrectIndexes.includes(labelIndex)
                    ? "write_answers_write_box_wrong"
                    : "write_answers_write_box"
                  : "write_answers_write_box"
              }
              style={{ gridTemplateColumns: left + "% " + right + "%" }}
            >
              <InputGroup>
                <Input
                  variant="unstyled"
                  type="text"
                  className="write_answers_write_box_input"
                  value={answers[labelIndex]}
                  onChange={(e) => {
                    var newAnswers = [...answers];
                    newAnswers[labelIndex] = e.target.value;
                    setAnswers(newAnswers);
                  }}
                />
                {label !== "" && (
                  <InputRightAddon
                    children={label}
                    style={{
                      fontFamily: "Causten",
                      backgroundColor: "transparent",
                    }}
                  />
                )}
              </InputGroup>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="write_answers">
      <Background
        incorrectFeedback={incorrectFeedback}
        setIncorrectFeedback={setIncorrectFeedback}
      />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={imageExists} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={props.research}
      />
      <ConfirmButton confirmHandler={confirmHandler} />
      <HelpMenu helpText={helpText} tipHandler={props.tipHandler} />
      <ExerciseImage
        image={image}
        animationUrl={animationUrl}
        imageExists={imageExists}
      />
      <ExerciseName type={props.exercise.type} smallMargin={imageExists} />
      {renderWriteBoxes()}
    </div>
  );
};

export default WriteAnswers;
