import "./ProfessorActivitiesMenu.css";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { LazyMotion, m } from "framer-motion";

import Plus from "../../../assets/professor/plus.tsx";
import calendar from "../../../assets/professor/calendar_1.svg";
import GroupAddButton from "../../../assets/professor/studentAddGroup.tsx";
import OnePersonIcon from "../../../assets/professor/one_person_icon.svg";

import UnitMenu from "../marketplace/UnitMenu.tsx";
import { classes, menu } from "../../../reducers/teacherSlice.ts";
import Loading from "../../loading_screen/LoadingDots.tsx";
import { colors } from "../../../constants.ts";
import {
  CourseType,
  GetAllCoursesAPI,
} from "../../../_newapios/content/course.ts";
import {
  BundleType,
  GetClassBundlesAPI,
} from "../../../_newapios/user/class.ts";
import mainSlice from "../../../reducers/mainSlice.ts";
import { submenu } from "../../../reducers/teacherSlice";
import {
  GetAllUnitsAPI,
  ModifiedUnitType,
} from "../../../_newapios/content/unit.ts";
import { RegulationType } from "../../../_newapios/content/regulation.ts";
import { chooseLanguageFromDB } from "../../../utils/content.ts";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  TagRightIcon,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { GetAllGroupsAPI, GroupType } from "../../../_newapios/user/group.ts";
import { setGroupsFunc, studentsAreGroup } from "../../../utils/group.ts";

const ProfessorActivitiesMenu = ({ ...props }) => {
  const { t } = useTranslation(["common", "evaluation"]);
  const teacherState = useAppSelector((state) => state.teacher);
  const mainState = useAppSelector((state) => state.main);
  const regulations = mainState.regulations;

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [activitySubmenu, setActivitySubmenu] = useState("none");
  const [bundles, setBundles] = useState<BundleType[]>();
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [selectedBundle, setSelectedBundle] = useState<BundleType | undefined>(
    undefined
  );
  const subjects = mainState.courses;
  const [subjectValue, setSubjectValue] = useState<CourseType | null>(null);

  // Filter courses based on regulation_id

  const getCoursesByRegulation = (regulationIds: string[]) => {
    if (!regulationIds || regulationIds.length === 0) return subjects;
    return subjects.filter((course) =>
      regulationIds.includes(course.regulation_id)
    );
  };

  const selectedClass = teacherState.selectedClass;

  useEffect(() => {
    setActivitySubmenu("none");
    setSubjectValue(null);
  }, [teacherState.selectedClass]);

  useEffect(() => {
    if (teacherState.selectedClass === null) {
      return;
    }
    setLoading(true);
    const promises = [
      GetClassBundlesAPI(teacherState.selectedClass?.id).then((bundle) => {
        setBundles(bundle);
      }),
      GetAllGroupsAPI(teacherState.selectedClass?.id).then((data) => {
        setGroupsFunc(
          mainState.students,
          teacherState.selectedClass?.id || "",
          data,
          setGroups
        );
      }),
    ];
    Promise.all(promises).then(() => setLoading(false));
  }, [
    mainState.students,
    teacherState.selectedClass,
    teacherState.submenu,
    teacherState.menu,
    activitySubmenu,
  ]);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const activitySubmenuHandler = (
    newSubmenu: string,
    bundle: BundleType | undefined = undefined
  ) => {
    if (selectedBundle !== undefined && bundle === undefined) {
      setSelectedBundle(undefined);
    } else if (bundle !== null) {
      setSelectedBundle(bundle);
    }
    setActivitySubmenu(newSubmenu);
  };

  const subjectHandler = (value: any) => {
    setSubjectValue(value);
  };

  const renderCourseMenu = (title: string, courses: CourseType[]) => {
    return (
      <div>
        <MenuGroup
          title={t(title)}
          style={{
            fontFamily: "Causten",
          }}
        >
          {courses.map((subj) => (
            <MenuItem
              key={subj.id}
              style={{
                fontFamily: "Causten",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              onClick={() => subjectHandler(subj)}
            >
              {t(subj.acronym + "_name", { ns: "evaluation" })}
            </MenuItem>
          ))}
        </MenuGroup>
      </div>
    );
  };

  const renderTop = () => {
    return (
      <div className="professor_activities_menu_top">
        <LazyMotion features={loadFeatures}>
          <m.div
            whileHover={{
              scale: 1.05,
              boxShadow: "0px 0px 10px 2px rgba(110, 230, 167, 0.50)",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className="professor_activities_menu_top_new"
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            onClick={() => dispatch(menu("marketplace"))}
          >
            <div className="center_container">
              <Plus color={"white"} />
              <div
                className="text_white_small"
                style={{ whiteSpace: "nowrap", marginLeft: "3%" }}
              >
                {t("create_activity")}
              </div>
            </div>
          </m.div>
        </LazyMotion>

        <LazyMotion features={loadFeatures}>
          <div />
          <div className="professor_activities_menu_top_filter">
            <Menu variant="outline">
              <MenuButton
                // variant="outline"
                style={{
                  maxWidth: "17vw",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "50px",
                  boxSizing: "border-box",
                  paddingTop: "0.1em",
                  paddingBottom: "0.1em",
                  paddingLeft: "1em",
                  paddingRight: "1em",
                  backgroundColor: subjectValue
                    ? subjectValue.color
                    : undefined,
                  boxShadow: subjectValue
                    ? undefined
                    : "inset 0 0 0 2px #c9c9c9",

                }}
                as={m.div}
                whileHover={{
                  scale: 1.05,
                }}
              >
                <div className="center_container" >
                  {_.isUndefined(subjectValue) || _.isNull(subjectValue) ? (
                    <div className="text_grey_small_bold" >{t("subject")}</div>
                  ) : (
                    <div className="text_white_small_bold" style={{
                      maxWidth: "15vw",
                      display: 'inline-block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                      {t(subjectValue.acronym + "_name", { ns: "evaluation" })}
                    </div>
                  )}

                  <TagRightIcon
                    boxSize="3vh"
                    as={ChevronDownIcon}
                    style={{ color: subjectValue ? "white" : "#c9c9c9" }}
                  />
                </div>
              </MenuButton>
              <MenuList style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                <MenuItem
                  style={{
                    fontFamily: "Causten",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={() => subjectHandler(null)}
                >
                  {t("all_subjects")}
                </MenuItem>
                {regulations.filter((regulation) =>
                  getCoursesByRegulation([regulation.id]).some((course) =>
                    bundles?.some((bundle) => bundle.unit?.course_id === course.id)
                  )
                ).map((regulation: RegulationType) => {
                  const filteredCourses = getCoursesByRegulation([regulation.id]).filter(
                    (course) =>
                      bundles?.some((bundle) => bundle.unit?.course_id === course.id)
                  );

                  return (
                    <MenuGroup
                      title={t(regulation.name, { ns: "evaluation" })}
                      style={{
                        fontFamily: "Causten",
                        borderBottom: "1px solid " + regulation.color,
                      }}
                    >
                      {filteredCourses.map((course) => (
                        <MenuItem
                          key={course.id}
                          style={{
                            fontFamily: "Causten",
                            cursor:
                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                          }}
                          _hover={{ bg: regulation.color + "50" }}
                          onClick={() => subjectHandler(course)}
                        >
                          {t(course.acronym + "_name", { ns: "evaluation" })}
                        </MenuItem>
                      ))}
                    </MenuGroup>
                  );
                })}
              </MenuList>
            </Menu>
          </div>
        </LazyMotion>
        <LazyMotion features={loadFeatures}>
          <m.div
            whileHover={{ scale: 1.03, boxShadow: "0px 0px 10px 0px #5090F0" }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            className="professor_activities_menu_top_calendar"
            onClick={() => dispatch(submenu("calendar"))}
          >
            <div className="center_container">
              <img
                className="icon"
                src={calendar}
                alt="calendar"
                style={{ marginRight: "3%" }}
              />
              <div className="text_blue_small" style={{ whiteSpace: "nowrap" }}>
                {t("calendar")}
              </div>
            </div>
          </m.div>
        </LazyMotion>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="professor_activities_menu_main">
        {bundles
          ?.filter((bundle) =>
            subjectValue === null
              ? true
              : bundle.unit?.course_id === subjectValue?.id
          )
          .map((bundle, index) => {
            return renderActivity(bundle, index);
          })}
      </div>
    );
  };

  const renderActivity = (bundle: BundleType, bundleIndex: number) => {
    var tempSelectedUnit = bundle.unit; // TODO - Bundle has unit why tf are we doing this?
    if (tempSelectedUnit === undefined) {
      return;
    }
    var unitSubject = subjects.find(
      (subject) => subject.id === tempSelectedUnit?.course_id
    );
    if (bundles === undefined) {
      return;
    }
    var bundleDates = [bundle.start_date, bundle.end_date];
    if (bundleDates === undefined) return;
    const studentIdList = bundle.students?.map((student) => student.id);
    const areStudentsGroup = studentsAreGroup(groups, studentIdList || []);
    var isWholeClass = areStudentsGroup?.id === "group1";
    var isNotGroup = areStudentsGroup === null;

    const selectedStudent =
      studentIdList?.length === 1
        ? bundle.students?.find((student) => student.id === studentIdList[0])
        : null;

    const tooltipLabel = isNotGroup
      ? selectedStudent
        ? t("associated_to") + selectedStudent?.username
        : t("associated_to") +
        studentIdList?.length +
        " " +
        t("students").toLowerCase() // 1 student or some students
      : isWholeClass
        ? t("associated_whole_class")
        : t("associated_group") + (areStudentsGroup ? areStudentsGroup.name : "");

    return (
      <LazyMotion features={loadFeatures} key={bundle.id}>
        <m.div
          whileHover={{
            scale: 1.01,
            boxShadow: "0px 0px 10px 0px rgba(80, 144, 240, 0.50)",
          }}
          transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
          className="professor_activities_menu_main_activity"
          onClick={() => activitySubmenuHandler("unit", bundle)}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            marginTop: bundleIndex === 0 ? "0%" : "2%",
          }}
          key={bundle.id}
        >
          <div className="professor_activities_menu_main_activity_left left_container">
            <div className="professor_activities_menu_main_activity_left_name">
              <div
                className="text_black_very_small_bold"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {tempSelectedUnit.name}
              </div>
            </div>
            {/* <div className="professor_activities_menu_main_activity_left_activities">
              <div className="text_light_grey_super_small">
                {tempSelectedUnit.scenario_count + " " + t("activities_lc")}
              </div>
            </div> */}
            <div
              className="professor_activities_menu_main_activity_left_course"
              style={{ background: unitSubject?.color }}
            >
              <div className="center_container">
                <div className="text_white_super_small">
                  {t(unitSubject?.acronym + "_short", { ns: "evaluation" })}
                </div>
              </div>
            </div>
            <div
              className="professor_activities_menu_main_activity_left_age"
              style={{
                backgroundColor: regulations.find((regulation) => {
                  return regulation.id === tempSelectedUnit?.regulation_id;
                })?.color,
              }}
            >
              <div className="center_container">
                <div className="text_white_super_small">
                  {" "}
                  {t(
                    regulations.find(
                      (regulation) =>
                        regulation.id === tempSelectedUnit?.regulation_id
                    )?.name || "",
                    { ns: "evaluation" }
                  )}
                </div>
              </div>
            </div>
            <div className="professor_activities_menu_main_activity_left_lang">
              <div className="center_container">
                <div className="text_white_super_small">
                  {t(chooseLanguageFromDB(tempSelectedUnit.language))}
                </div>
              </div>
            </div>
          </div>
          <div className="professor_activities_menu_main_activity_right right_container">
            <div className="professor_activities_menu_main_activity_right_type">
              <div className="text_black_super_small_bold">
                {t("research") + " | " + t("experiment")}
              </div>
            </div>
            <div className="professor_activities_menu_main_activity_right_dates">
              <div className="text_light_grey_very_small">
                {new Date(bundleDates[0]).toLocaleDateString() +
                  " - " +
                  new Date(bundleDates[1]).toLocaleDateString()}
              </div>
            </div>
            <Tooltip
              hasArrow
              label={tooltipLabel}
              bg={"#7D7D7D"}
              placement="top"
              sx={{
                fontFamily: "Causten",
                fontSize: "calc(5px + 0.5vw)",
                borderRadius: "5px",
              }}
              aria-label="Group Name Tooltip"
            >
              <div className="professor_activities_menu_main_activity_right_group">
                {isNotGroup ? (
                  <img src={OnePersonIcon} style={{ maxHeight: "5vh" }} />
                ) : (
                  <GroupAddButton
                    color={isWholeClass ? "#6EE6A7" : "#5090F0"}
                    size={28}
                  />
                )}
              </div>
            </Tooltip>
          </div>
        </m.div>
      </LazyMotion>
    );
  };
  return (
    <div
      className="professor_activities"
      style={loading ? { display: "flex" } : {}}
    >
      {loading && <Loading />}
      {!loading && activitySubmenu === "none" && (
        <div className="professor_activities_menu">
          {renderTop()}
          {renderMain()}
        </div>
      )}
      {!loading && activitySubmenu === "unit" && (
        <UnitMenu
          backHandler={activitySubmenuHandler}
          closeFakeUnitEditMenu={() => { }}
          bundle={selectedBundle}
          unit={selectedBundle?.unit}
          subjects={subjects}
        />
      )}
    </div>
  );
};

export default ProfessorActivitiesMenu;
