import "./Campus.css";
import { useState, useEffect, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import closeButton from "../../../assets/student/close_button.svg";
import BackArrow from "../../../assets/exercises/back_arrow";
import Time from "../../../assets/student/time";
import Calendar from "../../../assets/student/calendar";
import { colors } from "../../../constants";
import Loading from "../../loading_screen/LoadingDots";
import { motion } from "framer-motion";

import Cookies from "universal-cookie";
import { IscreenSize } from "../../../interfaces";
import { campusMenu, menu, points } from "../../../reducers/studentSlice";
import { GetStudentBundlesAPI } from "../../../_newapios/content/bundle";
import { ModifiedBundleType } from "../../../_newapios/user/class";
import {
  ExperimentProgressType,
  GetMyStudentProgressAPI,
  GetMyStudentScenarioProgressAPI,
  ResearchProgressType,
  ScenarioProgressType,
} from "../../../_newapios/progress/student_scenario";
import {
  ExerciseType,
  ModifiedUnitType,
  ScenarioType,
  UnitType,
} from "../../../_newapios/content/unit";

import ScenarioMenu from "./ScenarioMenu.tsx";
import { getUnitProgressPoints } from "../../../utils/progress.ts";
import {
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuGroup,
  TagRightIcon,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { ChevronDownIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { CourseType } from "../../../_newapios/content/course.ts";

export interface Isubjects {
  topics: any[];
  on_time_array: any[];
  datesArray: any[];
  lang: any[];
  images: string[][];
  points: number[];
}

const Campus = () => {
  const { t, i18n } = useTranslation(["common", "evaluation"]);

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const dispatch = useAppDispatch();
  const cookies = new Cookies();

  const mainState = useAppSelector((state) => state.main);
  const studentState = useAppSelector((state) => state.student);

  //const user = useAppSelector(state => state.login).user

  const [selectedSubject, setSelectedSubject] = useState<CourseType | null>(
    null
  );
  const [selectedUnit, setSelectedUnit] = useState<UnitType | null>(null);
  const [selectedScenario, setSelectedScenario] = useState<ScenarioType | null>(
    null
  );

  const [bundles, setBundles] = useState<ModifiedBundleType[]>([]);
  const [filteredCourses, setFilteredCourses] = useState<CourseType[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const [scenarioProgresses, setScenarioProgresses] = useState<
    ScenarioProgressType[]
  >([]);

  const [loadingCourse, setLoadingCourse] = useState(false);

  const sortingOptions = [
    "name",
    "points",
    "due_date",
    "subject",
    "start_date",
  ];

  const [selectedSorting, setSelectedSorting] = useState("due_date");

  useEffect(() => {
    loadProgress();
  }, []);

  useEffect(() => {
    loadSubjects();
  }, [scenarioProgresses]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const loadProgress = async () => {
    const studentProgress = await GetMyStudentProgressAPI("me");
    setScenarioProgresses(studentProgress);
  };
  // Get progress of single scenario for percentage done
  const loadScenarioProgress = async (scenarioId: string) => {
    const scenarioIndex = scenarioProgresses.findIndex(
      (scenario) => scenario.id === scenarioId
    );
    if (scenarioIndex === -1) return loadProgress();
    const scenarioProgress = await GetMyStudentScenarioProgressAPI(
      "me",
      scenarioId
    );
    const newScenarioProgresses = [...scenarioProgresses];
    newScenarioProgresses[scenarioIndex] = scenarioProgress;
    setScenarioProgresses(newScenarioProgresses);
  };

  const loadSubjects = async () => {
    //setSubjects with all the subjects of the class from the db
    //setSelectedSubjectHandler(0)
    const getCourseFoundforStudents = async () => {
      var foundCourses = mainState.courses;
      var tempBundles = await GetStudentBundlesAPI("me");

      var tempFilteredCourses = foundCourses.filter((course) => {
        return tempBundles.find(
          (bundle) => bundle.unit.course_id === course.id
        );
      });

      setFilteredCourses(tempFilteredCourses);

      setBundles(tempBundles);
      setLoadingCourse(false);
    };
    getCourseFoundforStudents();
  };

  const closeButtonHandler = () => {
    dispatch(menu("globalMap"));
  };

  const openSituationMenu = async (situation: ScenarioType) => {
    console.info(situation);
    setSelectedScenario(situation);
  };

  const openUnitMenu = async (unit: UnitType) => {
    setSelectedUnit(unit);
    if (unit.scenarios.length === 0) return;
    setSelectedScenario(unit.scenarios[0]);
    // setSelectedSubject(unit.course_id - 1);
  };

  const closeUnitMenu = () => {
    //setSelectedTopic(0)
    setSelectedUnit(null);
  };

  const subjectHandler = (value: CourseType | null) => {
    setSelectedSubject(value);
  };

  const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const sortingHandler = (value: string) => {
    setSelectedSorting(value);
  };

  const loadScenarioResearchProgress = (
    scenario_id: string
  ): ResearchProgressType | null => {
    const scenarioProgress = scenarioProgresses.find(
      (prog) => prog.scenario_id === scenario_id
    );
    const researchProgress = scenarioProgress?.research_progress;

    if (researchProgress === undefined) return null;

    return researchProgress;
  };

  const loadScenarioExperimentProgress = (
    scenario_id: string
  ): ExperimentProgressType | null => {
    const scenarioProgress = scenarioProgresses.find(
      (prog) => prog.scenario_id === scenario_id
    );
    const experimentProgresses = scenarioProgress?.experiment_progress;
    if (experimentProgresses === undefined) return null;
    const experimentProgress = _.maxBy(
      experimentProgresses.filter(
        (prog) => prog.priority_index === experimentProgresses[0].priority_index
      ),
      (prog) => prog.completed_on
    );
    if (experimentProgress === undefined) return null;
    return experimentProgress;
  };

  const filterBundles = (bundles: ModifiedBundleType[]) => {
    var filteredBundles;

    if (selectedSubject === null) {
      filteredBundles = bundles;
    } else {
      filteredBundles = bundles.filter((bundle) => {
        return bundle.unit.course_id === selectedSubject.id;
      });
    }

    if (searchValue !== "") {
      filteredBundles = filteredBundles.filter((bundle) => {
        return bundle.unit.name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    }

    return filteredBundles;
  };

  const sortBundles = (bundles: ModifiedBundleType[]) => {
    return bundles
      .sort((a, b) => {
        switch (selectedSorting) {
          case "name":
            return a.unit.name.localeCompare(b.unit.name);
          case "points":
            return (
              getUnitProgressPoints(
                scenarioProgresses.filter((scenarioProgress) => {
                  return a.unit.scenarios
                    .map((scenario) => scenario.id)
                    .includes(scenarioProgress.scenario_id);
                })
              ) -
              getUnitProgressPoints(
                scenarioProgresses.filter((scenarioProgress) => {
                  return b.unit.scenarios
                    .map((scenario) => scenario.id)
                    .includes(scenarioProgress.scenario_id);
                })
              )
            );
          case "due_date":
            return (
              new Date(a.end_date).getTime() - new Date(b.end_date).getTime()
            );
          case "subject":
            return (
              filteredCourses.find((course) => course.id === a.unit.course_id)
                ?.acronym || ""
            ).localeCompare(
              filteredCourses.find((course) => course.id === b.unit.course_id)
                ?.acronym || ""
            );
          case "start_date":
            return (
              new Date(b.start_date).getTime() -
              new Date(a.start_date).getTime()
            );

          default:
            return 0;
        }
      })
      .sort((a, b) => {
        if (
          getUnitProgressPoints(
            scenarioProgresses.filter((scenarioProgress) => {
              return a.unit.scenarios
                .map((scenario) => scenario.id)
                .includes(scenarioProgress.scenario_id);
            })
          ) === 4
        ) {
          return 1;
        }
        if (
          getUnitProgressPoints(
            scenarioProgresses.filter((scenarioProgress) => {
              return b.unit.scenarios
                .map((scenario) => scenario.id)
                .includes(scenarioProgress.scenario_id);
            })
          ) === 4
        ) {
          return -1;
        }
        return 0;
      });
  };

  const renderCloseButton = () => {
    return (
      <motion.div
        className="campus_close_button"
        whileHover={{ scale: 1.2, rotate: 180 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        onClick={() => closeButtonHandler()}
      >
        <div className="center_container">
          <img className="image" src={closeButton} alt="close" />
        </div>
      </motion.div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="campus_title">
        <div className="left_container">
          <div className="text_white_very_big_bold">{t("campus")}</div>
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className="campus_filters">
        <div className="campus_filters_filter">
          {renderSearchFilter()}
          {renderSubjectsFilter()}
        </div>
        <div className="campus_filters_sorting">{renderSorting()}</div>
      </div>
    );
  };

  const renderSearchFilter = () => {
    return (
      <InputGroup
        borderRadius={"3px"}
        w={"20vw"}
        minW={"15vw"}
        ml={"2.5%"}
        color={"white"}
      >
        <Input
          placeholder={t("search_placeholder")}
          size="md"
          style={{
            fontFamily: "Causten",
            fontSize: "calc(5px + 1vw)",
            fontWeight: "bold",
          }}
          as={motion.input}
          variant="outline"
          value={searchValue}
          onChange={searchHandler}
          whileHover={{
            scale: 1.01,
            boxShadow: "0px 0px 10px 1px #ffffff",
          }}
          _focusVisible={{
            boxShadow: "0px 0px 10px 1px #ffffff",
            borderColor: "white",
          }}
          transition="0.1s ease"
          _placeholder={{ color: "white" }}
        />
        <InputRightElement onClick={() => setSearchValue("")}>
          <SmallCloseIcon color="white" />
        </InputRightElement>
      </InputGroup>
    );
  };

  const renderSubjectsFilter = () => {
    return (
      <Menu variant="outline">
        <MenuButton
          // variant="outline"
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            display: "flex",
            flexDirection: "column",
            borderRadius: "50px",
            boxSizing: "border-box",
            paddingTop: "0.1em",
            paddingBottom: "0.1em",
            paddingLeft: "1em",
            paddingRight: "1em",
            marginRight: "2.5%",
            backgroundColor: selectedSubject
              ? selectedSubject.color
              : undefined,
            boxShadow: selectedSubject ? undefined : "inset 0 0 0 2px #ffffff",
          }}
          as={motion.div}
          whileHover={{
            scale: 1.05,
          }}
        >
          <div className="center_container">
            <div className="text_white_small_bold">
              {_.isUndefined(selectedSubject) || _.isNull(selectedSubject)
                ? t("subject")
                : t(selectedSubject.acronym + "_name", { ns: "evaluation" })}
            </div>

            <TagRightIcon
              boxSize="3vh"
              as={ChevronDownIcon}
              style={{ color: "white" }}
            />
          </div>
        </MenuButton>
        <MenuList style={{ maxHeight: "40vh", overflowY: "scroll" }}>
          <MenuItem
            style={{
              fontFamily: "Causten",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            onClick={() => subjectHandler(null)}
          >
            {t("all_subjects")}
          </MenuItem>
          {filteredCourses.map((course) => {
            return (
              <MenuItem
                key={course.id}
                style={{
                  fontFamily: "Causten",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => subjectHandler(course)}
              >
                {t(course.acronym + "_name", { ns: "evaluation" })}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  };

  const renderSorting = () => {
    return (
      <Menu variant="ghost">
        <MenuButton
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            display: "flex",
            flexDirection: "column",
            borderRadius: "50px",
            boxSizing: "border-box",
            paddingTop: "0.1em",
            paddingBottom: "0.1em",
            paddingLeft: "1em",
            paddingRight: "1em",
            marginRight: "2.5%",
          }}
        >
          <div className="center_container">
            <div className="text_white_small" style={{ marginRight: "0.3em" }}>
              {t("sort_by")}
            </div>
            <div className="text_white_small_bold">{t(selectedSorting)}</div>

            <TagRightIcon
              boxSize="3vh"
              as={ChevronDownIcon}
              style={{ color: "white" }}
            />
          </div>
        </MenuButton>
        <MenuList style={{ maxHeight: "40vh", overflowY: "scroll" }}>
          {sortingOptions.map((option) => {
            return (
              <MenuItem
                key={option}
                style={{
                  fontFamily: "Causten",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => sortingHandler(option)}
              >
                {t(option)}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  };

  const renderBody = () => {
    if (loadingCourse) {
      return <Loading />;
    }

    const MAIN_MENU_OPEN = selectedUnit === null && selectedScenario === null;
    if (MAIN_MENU_OPEN) {
      return !_.isEmpty(bundles) ? renderMain() : renderEmpty();
    }

    const UNIT_OPEN = selectedUnit !== null && selectedScenario === null;
    if (UNIT_OPEN) {
      return selectedUnit?.scenarios.length !== 0
        ? renderScenarios()
        : renderEmpty();
    }
    const SCENARIO_OPEN = selectedScenario !== null;

    if (SCENARIO_OPEN) {
      const bundle = bundles?.find(
        (bundle) => bundle.unit_id === selectedUnit?.id
      );
      const researchProgress = loadScenarioResearchProgress(
        selectedScenario.id
      );
      const experimentProgress = loadScenarioExperimentProgress(
        selectedScenario.id
      );
      if (bundle === undefined) return;
      const unit = bundle?.unit;
      const color = mainState.courses.find(
        (course) => course.id === unit.course_id
      )?.color;
      return (
        selectedScenario !== null &&
        !loadingCourse && (
          <ScenarioMenu
            bundle={bundle}
            scenario_id={selectedScenario.id}
            researchProgress={researchProgress}
            experimentProgress={experimentProgress}
            color={color}
            updateProgress={() => loadScenarioProgress(selectedScenario.id)}
            closeSituationMenu={() => {
              loadScenarioProgress(selectedScenario.id);
              setSelectedScenario(null);
              setSelectedUnit(null);
            }}
          />
        )
      );
    }
  };

  const renderEmpty = () => {
    return (
      <div className="campus_subject_units_loading">
        <div className=" center_container">
          <div className="text_white_small">Nothing to see here...</div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="campus_main">
        {bundles !== undefined &&
          sortBundles(filterBundles(bundles)).map((bundle) => {
            // JANK - It do be like that sometimes
            return <div>{renderUnit(bundle)}</div>;
          })}
      </div>
    );
  };

  const renderUnit = (bundle: ModifiedBundleType) => {
    const unit = bundle.unit;
    const banner = unit.scenarios.find(() => true)?.banner.url;
    const color = mainState.courses.find(
      (course) => course.id === unit.course_id
    )?.color;
    const courseName = t(
      mainState.courses.find((course) => course.id === unit.course_id)
        ?.acronym + "_short",
      { ns: "evaluation" }
    );
    const unitProgress = scenarioProgresses.filter((scenarioProgress) => {
      return unit.scenarios
        .map((scenario) => scenario.id)
        .includes(scenarioProgress.scenario_id);
    });
    const points = getUnitProgressPoints(unitProgress);
    return (
      <motion.div
        className="campus_main_unit"
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px " + color,
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        onClick={() => {
          openUnitMenu(unit);
        }}
      >
        <div className="campus_main_unit_banner">
          <img
            src={banner}
            alt="image"
            style={{
              borderRadius: "5px 5px 0px 0px",
              objectFit: "cover",
              height: "100%",
              width: "100%",
            }}
          />
        </div>
        <div className="campus_main_unit_mid">
          <div className="left_container">
            <div className="campus_main_unit_mid_left">
              <div className="left_container">
                <div className="text_black_small_bold">{unit.name}</div>
              </div>
              <div className="campus_main_unit_mid_left_bottom">
                <div className="left_container">
                  <div
                    className="text_black_very_small"
                    style={{ color: "#676767" }}
                  >
                    {unit.scenarios.length === 1 ? unit.scenarios[0].name : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right_container">
            <div className="campus_main_unit_mid_right">
              <div className="center_container">
                {/* HAHAHAHAHAHHAAH, school 22 be like -____- */}
                {
                  /* {user.school_id !== 22*/ true && (
                    <div
                      className="course_tag_background"
                      style={{
                        backgroundColor: color,
                      }}
                    >
                      {" "}
                      {/* This is hardcoded af */}
                      <div className="center_container">
                        <div className="text_white_very_small_bold">
                          {courseName}
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className="campus_main_unit_bot">
          <div
            className="campus_main_unit_bot_images"
            style={{
              gridTemplateColumns:
                "repeat(" + Math.max(unit.scenarios.length, 3) + ", 1fr)",
            }}
          >
            {" "}
            {/*Sometimes I hate Typescript*/}
            {/* Only sometimes? */}
            {unit.scenarios.map((scenario) => {
              if (
                scenario.image.url === null ||
                scenario.image.url === undefined ||
                scenario.image.url === ""
              )
                return <div />;
              return (
                <div className="campus_main_unit_bot_images_image center_container">
                  <img
                    src={scenario.image.url}
                    alt="image"
                    style={{
                      borderRadius: "5px",
                      objectFit: "cover",
                      maxWidth: "100%",
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="campus_main_unit_bot_points">
            <div className="center_container">
              <div className="text_black_very_small">
                <b
                  style={{
                    fontSize: "2em",
                    color: color,
                  }}
                >
                  {points}
                </b>
                <b
                  style={{
                    fontSize: "1.5em",
                    fontWeight: "normal",
                    color: color,
                  }}
                >
                  {" / " + 4 * unit.scenarios.length}
                </b>
                {" " + t("points")}
              </div>
            </div>
          </div>
          <div className="campus_main_unit_bot_date">
            <div className="center_container image" style={{ width: "90%" }}>
              <Time color={color} />
            </div>
            <div className="center_container">
              <div className="text_black_very_small">
                {t("due_date") + ": "}
                <b>
                  {new Date(bundle.end_date).toLocaleDateString(i18n.language, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </b>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  };

  const renderScenarios = () => {
    if (selectedUnit === null) return;
    const color =
      mainState.courses.find((course) => course.id === selectedUnit.course_id)
        ?.color || "";
    const courseName = t(
      mainState.courses.find((course) => course.id === selectedUnit.course_id)
        ?.acronym + "_name",
      { ns: "evaluation" }
    );
    const bundle = bundles?.find(
      (bundle) => bundle.unit_id === selectedUnit.id
    ) as ModifiedBundleType;
    return (
      <div className="campus_subject_units">
        <div className="campus_subject_units_banner">
          <img
            src={selectedUnit?.scenarios.find(() => true)?.banner.url}
            alt="image"
            style={{
              borderRadius: "5px 5px 0px 0px",
              objectFit: "cover",
              maxHeight: "100%",
              width: "100%",
              height: "100%",
              maxWidth: "100%",
            }}
          />
        </div>
        <div className="campus_subject_content_up">
          <motion.div
            className="left_container"
            whileHover={{
              scale: 1.05,
              textShadow: color + " 1px 0px 10px",
            }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.1 }}
          >
            <div
              onClick={() => closeUnitMenu()}
              style={{
                position: "relative",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              <BackArrow color={color} />
            </div>
            <div
              className="text_black_big_bold"
              onClick={() => closeUnitMenu()}
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              {selectedUnit?.name}
            </div>
          </motion.div>
          <div className="campus_subject_content_up_time">
            <div className="center_container image" style={{ width: "90%" }}>
              <Time color={color} />
            </div>
            <div className="left_container">
              <div className="text_black_small">
                {t("due_date") + ": "}
                <b>
                  {new Date(bundle.end_date).toLocaleDateString(i18n.language, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </b>
              </div>
            </div>
          </div>
          <div className="center_container">
            {/* TODO - HAHAHAHAHAHHAAH, school 22 be like -____- */}
            {
              /* {user.school_id !== 22*/ true && (
                <div
                  className="course_tag_background"
                  style={{
                    backgroundColor: color,
                    height: "undefined",
                  }}
                >
                  {" "}
                  {/* This is hardcoded af */}
                  <div className="center_container">
                    <div className="text_white_very_small_bold">
                      {courseName}
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
        <div
          className="campus_subject_content_body"
          style={{
            gridTemplateRows:
              selectedUnit.scenarios.length > 4
                ? "repeat(" +
                  Math.ceil(selectedUnit.scenarios.length / 2) +
                  ", 50%)"
                : "repeat(2, 50%)",
          }}
        >
          {bundles !== undefined &&
            selectedUnit &&
            selectedUnit.scenarios.map((scenario: ScenarioType) => {
              return (
                <div style={{ padding: "0.5em" }}>
                  {renderScenario(scenario, bundle, color)}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderScenario = (
    scenario: ScenarioType,
    bundle: ModifiedBundleType,
    color: string
  ) => {
    const experimentProgress = loadScenarioExperimentProgress(scenario.id);
    var pelotas = 0; //+ experimentProgress[0] + experimentProgress[1] + experimentProgress[2] + experimentProgress[3] // TODO - Our js tricks don't work anymore :'(

    if (experimentProgress !== null) {
      pelotas = experimentProgress.exercise_progress.reduce((acc, ans) => {
        var point = ans.correct ? 1 : 0;
        return acc + point;
      }, 0);
    }
    const picture_subtopic =
      scenario.image.url ||
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Asset+30%40300x12.svg";

    const description = scenario.description;
    return (
      <div
        className="campus_subject_subtopic"
        onClick={() => openSituationMenu(scenario)}
      >
        <div className="campus_subtopic_banner">
          <img
            src={picture_subtopic}
            alt="image"
            style={{
              border: color + " solid 5px",
              borderRadius: "5px",
              boxSizing: "border-box",
              position: "absolute",
              height: "100%",
              right: 0,
            }}
          />
        </div>
        <motion.div
          className="campus_subject_subtopic_content"
          style={{
            marginLeft: "1em",
            borderLeft: color + " solid 9px",
            boxShadow: "0px 0px 10px 0px " + color + "50",
          }}
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 10px 1px " + color + "50",
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <div className="campus_subject_subtopic_content_top">
            <div className="left_container">
              <div className="text_black_small_bold" style={{ color: color }}>
                {scenario.name}
              </div>
            </div>
          </div>
          <div className="campus_subject_subtopic_content_mid">
            <div className="top_left_container">
              <div className="text_black_really_small">
                {description.substr(0, 120) + "..."}
              </div>
            </div>
          </div>
          <div className="campus_subject_subtopic_content_bot">
            <div className="campus_subject_subtopic_content_bot_time">
              <div className="center_container">
                <Time color={color} />
              </div>
              <div className="left_container">
                <div className="text_black_super_small" style={{}}>
                  {t("due_date") + ": "}
                  <b>
                    {new Date(bundle.end_date).toLocaleDateString(
                      i18n.language,
                      {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      }
                    )}
                  </b>
                </div>
              </div>
            </div>
            <div className="campus_subject_subtopic_content_bot_pilotes center_container text_black_super_small">
              {[1, 1, 1, 1].map((_, index) => {
                if (index < pelotas) {
                  return (
                    <div
                      style={{
                        borderRadius: "0.5em",
                        marginRight: "1em",
                        display: "inline-block",
                        height: "1em",
                        width: "1em",
                        backgroundColor: color,
                      }}
                    />
                  );
                }
                return (
                  <div
                    style={{
                      borderRadius: "0.5em",
                      marginRight: "1em",
                      display: "inline-block",
                      height: "1em",
                      width: "1em",
                      backgroundColor: color + "20",
                    }}
                  />
                );
              })}
              <div>
                {" "}
                <b>{pelotas + "/4"}</b>
                {t("space_points")}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    );
  };

  return (
    <div className="campus">
      {(studentState.campusMenu === "campus" || true) && (
        <div className="campus_menu">
          <div className="campus_banner_container">
            <img
              className="campus_banner"
              src={
                "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/banner_lab.svg"
              }
              alt="banner"
            />
          </div>
          <div className="campus_background" />
          {renderCloseButton()}
          {renderTitle()}
          {selectedScenario === null && renderFilters()}
          <div
            className="campus_subjects"
            style={
              selectedScenario === null
                ? { height: "73.5%", top: "26.5%" }
                : { height: "80%", top: "20%" }
            }
          >
            {renderBody()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Campus;
