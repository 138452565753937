import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store.ts";
import "./i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ColorModeScript } from "@chakra-ui/react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { createStandaloneToast } from "@chakra-ui/react";

const container = document.getElementById("root")!;
const root = createRoot(container);
const { ToastContainer, toast } = createStandaloneToast();
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <Suspense fallback={null}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <App />
            <ToastContainer />
          </Suspense>
        </ChakraProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { toast };
