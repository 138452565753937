import "./_components.css";
import { motion } from "framer-motion";
import _ from "lodash";

import help from "../../../assets/exercises/help.svg";
import left from "../../../assets/exercises/left_arrow.svg";
import right from "../../../assets/exercises/right_arrow.svg";
import leftTransparent from "../../../assets/exercises/left_arrow_transparent.svg";
import rightTransparent from "../../../assets/exercises/right_arrow_transparent.svg";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import redBackground from "../../../assets/exercises/redbackgroundexercise.svg";

import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Idirection } from "../../../interfaces";
import { Spinner } from "@chakra-ui/react";
import axios from "axios";
import Lottie from "lottie-react";
import { useAppSelector } from "../../../hooks/hooks";
import { CourseType } from "../../../_newapios/content/course";

const pako = require("pako");

export const HelpMenu = (props: {
  helpText: string | undefined;
  tipHandler: () => void;
}) => {
  const { t } = useTranslation();

  const [helpOpen, setHelpOpen] = useState(false);
  const [helpText, setHelpText] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.helpText !== helpText) {
      setHelpText(props.helpText);
    }
  }, [props.helpText]);

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
    if (!helpOpen) {
      props.tipHandler();
    }
  };

  const renderHelpMenu = () => {
    return (
      <div
        className="exercise_help_background"
        onClick={() => helpButtonHandler()}
      >
        <div className="exercise_help_menu">
          <div className="center_container">
            {!_.isUndefined(helpText) && (
              <div
                className={
                  helpText.length < 265
                    ? "text_black_medium"
                    : "text_black_small"
                }
              >
                {helpText}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderHelpButton = () => {
    return (
      <div className="exercise_help_button">
        <div className="center_container">
          <motion.div
            className="exercise_help_button_text"
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #575757",
              borderRadius: "5 px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            onClick={() => helpButtonHandler()}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container" style={{ fontFamily: "Causten" }}>
              {t("see_clue")}
            </div>
          </motion.div>
        </div>
        <motion.div
          className="center_container"
          onClick={() => helpButtonHandler()}
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <img className="icon" src={help} alt="help" />
        </motion.div>
      </div>
    );
  };

  if (helpText === null && helpText === undefined && helpText === "") {
    return <div></div>;
  }

  return (
    <div className="help_menu">
      {helpOpen && renderHelpMenu()}
      {renderHelpButton()}
    </div>
  );
};

export const TextBox = (props: {
  text: string | undefined;
  contractTextBox: boolean;
}) => {
  const [text, setText] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.text !== text) {
      setText(props.text);
    }
  }, [props.text]);

  if (text === undefined && text === null) {
    return <div></div>;
  }

  return (
    <div
      className="exercise_text_box"
      style={{
        width: props.contractTextBox ? "28%" : "90%",
        left: props.contractTextBox ? "2%" : "5%",
      }}
    >
      <div className="center_container">
        {!_.isUndefined(text) && (
          <div
            className={
              text.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            {text}
          </div>
        )}
      </div>
    </div>
  );
};

export const Arrows = (
  props: {
    selectedExercise: number;
    maxExercise: number;
    updateLevel: (
      direction: Idirection,
      answer: string[] | null,
      correct: boolean | null
    ) => void;
    research: boolean;
    allowNext?: boolean;
  } = {
    selectedExercise: 0,
    maxExercise: 0,
    updateLevel: () => {},
    research: false,
    allowNext: false,
  }
) => {
  const arrowsHandler = (direction: Idirection) => {
    if (props.allowNext) {
      if (props.selectedExercise < props.maxExercise) {
        props.updateLevel(direction, null, null);
      } else {
        props.updateLevel(direction, [""], true);
      }
    }
    props.updateLevel(direction, null, null);
  };

  if (!props.research) {
    return <div></div>;
  }
  console.log(props.selectedExercise, props.maxExercise, props.allowNext);

  return (
    <div className="exercise_arrows">
      <motion.div
        className="center_container"
        onClick={
          props.selectedExercise === 0 ? () => {} : () => arrowsHandler("left")
        }
        whileHover={{ scale: props.selectedExercise === 0 ? 1 : 1.3 }}
        style={{
          cursor:
            props.selectedExercise === 0
              ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
              : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <img
          className="icon"
          src={props.selectedExercise === 0 ? leftTransparent : left}
          alt="left"
        />
      </motion.div>
      <motion.div
        className="center_container"
        onClick={
          props.allowNext
            ? () => {
                console.log("aquí");
                arrowsHandler("right");
              }
            : props.selectedExercise < props.maxExercise
            ? () => arrowsHandler("right")
            : () => {}
        }
        whileHover={{
          scale:
            props.allowNext || props.selectedExercise < props.maxExercise
              ? 1.3
              : 1,
        }}
        style={{
          cursor:
            props.allowNext || props.selectedExercise < props.maxExercise
              ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto'
              : 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto',
        }}
      >
        <img
          className="icon"
          src={
            props.allowNext || props.selectedExercise < props.maxExercise
              ? right
              : rightTransparent
          }
          alt="right"
        />
      </motion.div>
    </div>
  );
};

export const ConfirmButton = (props: { confirmHandler: () => void }) => {
  const { t } = useTranslation();

  return (
    <motion.div
      className="exercise_confirm"
      onClick={() => props.confirmHandler()}
      whileHover={{
        scale: 1.02,
        boxShadow: "0px 0px 10px 1px #5090F0",
        borderRadius: "5 px",
      }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
      style={{
        cursor:
          'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
      }}
    >
      <div className="center_container">
        <div className="text_white_medium_bold">{t("confirm")}</div>
      </div>
    </motion.div>
  );
};

export const Professor = (props: { width: number }) => {
  if (props.width < 992) {
    return <div></div>;
  }
  return (
    <div className="exercise_professor">
      <div className="bottom_left_container">
        <img
          className="image"
          src={
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
          }
          alt="professor"
        />
      </div>
    </div>
  );
};

export const Background = (props: {
  incorrectFeedback: boolean;
  setIncorrectFeedback: (value: SetStateAction<boolean>) => void;
}) => {
  setTimeout(() => {
    props.setIncorrectFeedback(false);
  }, 2000);
  return (
    <img
      className="background"
      src={props.incorrectFeedback ? redBackground : blueBackground}
      alt="background"
    />
  );
};

export const ExerciseImage = (props: {
  image: string | undefined;
  animationUrl: string | undefined;
  imageExists: boolean;
}) => {
  const mainState = useAppSelector((state) => state.main);
  const animations = mainState.animations;
  const [animation, setAnimation] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState<CourseType>(mainState.courses[0]);

  const fetchGzipJSON = async (animation_url: string) => {
    if (animation_url === "") {
      return;
    }
    // let inflatedJSON = {};
    let inflatedData = {};
    try {
      // Fetch the GZIP compressed data
      const { data } = await axios.get(animation_url, {
        responseType: "arraybuffer",
        decompress: true,
      });
      // Decompress the data using pako
      // inflatedJSON = JSON.parse(pako.inflate(data, { to: "string" }));
      inflatedData = pako.inflate(data, { to: "string" });

      const json = JSON.parse(inflatedData as string);

      setAnimation(json);
    } catch (error) {
      console.error("could not fetch gzip json", error);
    }
  };

  useEffect(() => {
    const temp_course = mainState.courses.find(
      (course) => course.id === mainState.selectedCourseId
    );
    if (temp_course) setCourse(temp_course);
  }, [mainState.selectedCourseId]);

  useEffect(() => {
    if (props.animationUrl !== undefined) {
      fetchGzipJSON(props.animationUrl);
    }
  }, [props.animationUrl]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  if (!props.imageExists) {
    return <div></div>;
  }

  if (animation === "") {
    if (
      props.image !== undefined &&
      props.image !== null &&
      props.image !== ""
    ) {
      return (
        <div className="exercise_image">
          <div className="center_container">
            {loading && (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color={course.color}
                size="xl"
              />
            )}
            <img
              className="image"
              src={props.image}
              alt="image"
              onLoad={handleImageLoad}
              style={{ display: loading ? "none" : "block" }}
            />
          </div>
        </div>
      );
    } else {
      return <div className="exercise_image"></div>;
    }
  } else {
    if (animations) {
      return (
        <div className="exercise_image">
          <div className="center_container">
            <Lottie
              animationData={animation}
              loop={animations ? true : false}
              autoplay={true}
              initialSegment={animations ? undefined : [60, 60]}
            />
          </div>
        </div>
      );
    } else if (
      props.image !== undefined &&
      props.image !== null &&
      props.image !== ""
    ) {
      return (
        <div className="exercise_image">
          <div className="center_container">
            {loading && (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color={course.color}
                size="xl"
              />
            )}
            <img
              className="image"
              src={props.image}
              alt="image"
              onLoad={handleImageLoad}
              style={{ display: loading ? "none" : "block" }}
            />
          </div>
        </div>
      );
    } else {
      return <div className="exercise_image"></div>;
    }
  }
};

export const MainTextBox = (
  props: {
    mainText: string | undefined;
    definition?: boolean;
  } = {
    mainText: undefined,
    definition: false,
  }
) => {
  return (
    <div
      className={
        props.definition
          ? "exercise_definition_text_box"
          : "exercise_main_text_box"
      }
    >
      <div className="center_container">
        {!_.isUndefined(props.mainText) && (
          <div
            className={
              props.mainText.length < 265
                ? "text_black_medium"
                : "text_black_small"
            }
          >
            {props.mainText}
          </div>
        )}
      </div>
    </div>
  );
};

export const ExerciseName = (props: { type: string; smallMargin: boolean }) => {
  const { t } = useTranslation();

  return (
    <div
      className="exercise_name"
      style={{ left: props.smallMargin ? "2%" : "5%" }}
    >
      <div className="left_container">
        <div
          className="text_white_big_bold"
          style={{ textShadow: "0px 0px 2px #000000" }}
        >
          {t(props.type)}
        </div>
      </div>
    </div>
  );
};
