
export const chooseLanguageFromDB = (language: string | null | undefined): string => {
  const languages = [
    { value: "ES", label: "spanish" },
    { value: "es", label: "spanish" },
    { value: "EN", label: "english" },
    { value: "en", label: "english" },
    { value: "DE", label: "deutsch" },
    { value: "de", label: "deutsch" },
    { value: "FR", label: "french" },
    { value: "fr", label: "french" },
    { value: "CAT", label: "catalonian" },
    { value: "cat", label: "catalonian" },
    { value: "VAL", label: "valencian" },
    { value: "val", label: "valencian" },

  ];
  if (language === null || language === undefined) return "N/A"

  const lang = languages.find(l => l.value === language)
  if (lang === undefined) return "N/A"
  return lang.label
}