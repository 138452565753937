import "./LoadingScreen.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Ii18nStringObject } from "../../interfaces";
//import professor from '../../assets/Tutorial/Professor_2.png';

const LoadingScreen = ({ ...props }) => {
  const { t, i18n } = useTranslation();

  const loadingPhrases: Ii18nStringObject = {
    es: [
      "Plantando árboles...",
      "Construyendo casas...",
      "Llenando de agua el mar...",
      "¡Siguenos en Instagram!: eutopia.education",
      "Reciclando basura...",
      "Transformando electricidad...",
      "Clasificando información confidencial...",
      "Calculando el último dígito de pi...",
      "Llenando el globo de aire caliente...",
      "Contratando obreros...",
      "No cliques en ningún árbol 12 veces...",
      "Votando al impostor ඞ...",
      "Escondiendo alienígenas 👽...",
      "Plantando setas de Teemo...",
    ],
    en: [
      "Planting trees...",
      "Building houses...",
      "Filling the sea with water...",
      "Follow us on Instagram!: eutopia.education",
      "Recycling garbage...",
      "Transforming electricity...",
      "Engaging student engagement...",
      "Classifying information...",
      "Calculating the last digit of pi...",
      "Pumping up hot air balloon...",
      "Hiring construction workers...",
      "Dont click any tree 12 times...",
      "Voting out imposter ඞ...",
      "Hiding aliens 👽...",
      "Planting Teemo's mushrooms...",
    ],
    de: [""],
    cat: [
      "Plantant arbres...",
      "Construint cases...",
      "Omplint d'aigua la mar...",
      "Siguenos en Instagram!: eutopia.education",
      "Reciclant escombraries...",
      "Transformant electricitat...",
      "Classificant informació confidencial...",
      "Calculant l'últim dígit de pi...",
      "Omplint el globus d'aire calent...",
      "Contractant obrers...",
      "No cliquis en cap arbre 12 vegades...",
      "Votant a l'impostor ඞ...",
      "Amagant alienígenes 👽...",
      "Plantant bolets de Teemo...",
    ],
    val: [
      "Plantant arbres...",
      "Construint cases...",
      "Omplint d'aigua la mar...",
      "Siguenos en Instagram!: eutopia.education",
      "Reciclant escombraries...",
      "Transformant electricitat...",
      "Classificant informació confidencial...",
      "Calculant l'últim dígit de pi...",
      "Omplint el globus d'aire calent...",
      "Contractant obrers...",
      "No cliques en cap arbre 12 vegades...",
      "Votant a l'impostor ඞ...",
      "Amagant alienígenes 👽...",
      "Plantant bolets de Teemo...",
    ]
  };

  var loadingPhrase: string =
    loadingPhrases[i18n.language][
    Math.floor(Math.random() * loadingPhrases[i18n.language].length)
    ];

  //const [percentage, setPercentage] = useState(0)

  // useEffect (() => {
  //   var intervalId = setInterval(() => {
  //     setPercentage(percentage => {
  //       if(percentage < 100) {
  //         return percentage + 20
  //       } else {
  //         return percentage
  //       }
  //     })
  //   }, 400)
  //   return () => clearInterval(intervalId)
  // }, [])

  return (
    <div className="loading_screen">
      <div className="loading_image_container">
        <img
          src={
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/EutopiaLogo.svg"
          }
          alt="loading_image"
          className="loading_image"
        />
      </div>
      <div className="loading_text_container">
        <div className="loading_text">{t("loading_text")}</div>
      </div>

      <div className="loading_bar">
        <div className="loading_bar_right">
          <div
            className="loading_bar_center"
            style={{ width: props.percentage + "%" }}
          />
        </div>
      </div>

      <div className="loading_subtext_container">
        <div className="loading_subtext">{loadingPhrase}</div>
      </div>

      {/*This part is just style elements and stuff, Ernesto I hate you*/}

      <div className="white_bar_long" style={{ top: "40%", left: "1.67%" }} />
      <div
        className="white_bar_short"
        style={{ top: "44.9%", left: "2.71%" }}
      />
      <div className="white_bar_long" style={{ bottom: "2%", right: "2%" }} />
      <div className="white_bar_short" style={{ bottom: "2%", right: "3%" }} />
      <div
        className="white_mini_bar"
        style={{ top: "4.35%", left: "1.66667%" }}
      />
      <div
        className="white_mini_bar"
        style={{ top: "4.35%", left: "2.1875%" }}
      />
      <div
        className="white_mini_bar"
        style={{ top: "4.35%", left: "2.708%" }}
      />
      <div className="white_mini_bar" style={{ top: "4.35%", left: "3.23%" }} />
      <div className="white_mini_bar" style={{ top: "4.35%", left: "3.75%" }} />
      <div
        className="white_mini_bar"
        style={{ top: "4.35%", left: "4.271%" }}
      />
      <div className="white_circle" style={{ top: "4.35%", right: "2%" }} />
      <div className="white_circle" style={{ top: "4.35%", right: "4%" }} />
      <div className="white_circle" style={{ top: "4.35%", right: "6%" }} />
      <div className="white_circle" style={{ top: "4.35%", right: "8%" }} />
      <div className="white_circle" style={{ top: "7.5%", right: "2%" }} />
      <div className="white_circle" style={{ top: "7.5%", right: "4%" }} />
      <div className="white_circle" style={{ top: "7.5%", right: "6%" }} />
      <div className="white_circle" style={{ top: "10.65%", right: "2%" }} />
      <div className="white_circle" style={{ top: "10.65%", right: "4%" }} />
      <div className="white_circle" style={{ top: "13.80%", right: "2%" }} />
    </div>
  );
};

export default LoadingScreen;
