import { useState, useEffect } from "react";
import "./ExercisePreview.css";
import DefinitionIcon from "../../../../assets/exercises/definition_icon";
import TheoryIcon from "../../../../assets/exercises/theory_icon";

import ChooseManyEdit from "../exercise_templates/ChooseManyEdit";
import ChooseOneEdit from "../exercise_templates/ChooseOneEdit";
import DefinitionEdit from "../exercise_templates/DefinitionEdit";
import TheoryEdit from "../exercise_templates/TheoryEdit";
import TrueFalseEdit from "../exercise_templates/TrueFalseEdit";
import WriteAnswersEdit from "../exercise_templates/WriteAnswersEdit";
import DragDropListEdit from "../exercise_templates/DragDropListEdit";
import DragDropTwoListsEdit from "../exercise_templates/DragDropTwoListsEdit";
import DragDropOptionsEdit from "../exercise_templates/DragDropOptionsEdit";
import FillInTheGapsEdit from "../exercise_templates/FillInTheGapsEdit";
import MediaEdit from "../exercise_templates/MediaEdit";
import BackArrow from "../../../../assets/exercises/back_arrow";
import { colors } from "../../../../constants";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import ExerciseSelectorPage from "./exerciseSelectorPage";
import DragDropArrowsEdit from "../exercise_templates/DragDropArrowsEdit";
import { CourseType } from "../../../../_newapios/content/course";
import { ExerciseType } from "../../../../_newapios/content/unit";
import {
  CreateExerciseAPI,
  DeleteExerciseAPI,
  UpdateExerciseAPI,
} from "../../../../_newapios/content/exercise";
import MediaIcon from "../../../../assets/exercises/media_icon";
import { Input } from "@chakra-ui/react";
import { useAppSelector } from "../../../../hooks/hooks";

const ExercisePreviewResearch = (props: {
  subject: CourseType;
  language: string;
  research_id: string;
  exercises: ExerciseType[];
  backHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const [selectedExercise, setSelectedExercise] = useState(0);
  const [exercises, setExercises] = useState(props.exercises);
  const [openExerciseSelector, setOpenExerciseSelector] = useState(false);

  const [imageUrl, setImageUrl] = useState("");

  const teacherID = useAppSelector((state) => state.login.id);

  useEffect(() => {
    setExercises(props.exercises);
  }, [props.exercises]);

  useEffect(() => {
    if (exercises[selectedExercise] === undefined) {
      setOpenExerciseSelector(true);
    }
  }, [exercises, selectedExercise]);

  const normalizePriorityIndex = (
    exercises: ExerciseType[],
    new_exercise_index: number
  ) => {
    const newExerciseIndex = new_exercise_index + 0.5;
    const priorityIndexArray = exercises.map((exercise, index) => {
      return exercise.priority_index;
    });
    priorityIndexArray.push(newExerciseIndex);
    priorityIndexArray.sort();

    const newPriorityIndexArray = priorityIndexArray.map(
      (priorityIndex, index) => {
        return index;
      }
    );
    return newPriorityIndexArray;
  };

  const navbarClickHandler = (exerciseIndex: number) => {
    setSelectedExercise(exerciseIndex);
    setOpenExerciseSelector(false);
  };

  const createExerciseHandler = async (
    exerciseType:
      | "theory"
      | "truefalse"
      | "choosemany"
      | "chooseone"
      | "dragdropoptions"
      | "fillinthegaps"
      | "writeanswers"
      | "dragdroparrows"
      | "dragdroplist"
      | "dragdroptwolists"
      | "definition"
      | "media"
  ) => {
    var tempExercises = [...exercises];

    const priorityIndexArray = normalizePriorityIndex(
      tempExercises,
      selectedExercise
    );

    CreateExerciseAPI(
      props.language,
      exerciseType,
      tempExercises.length === 0 ? selectedExercise : selectedExercise + 1,
      priorityIndexArray,
      props.research_id,
      null
    ).then((data) => {
      setExercises(data.exercises);
      setSelectedExercise(selectedExercise + 1);
      if (selectedExercise + 1 > tempExercises.length) {
        setSelectedExercise(0);
      }
      setOpenExerciseSelector(false);
    });
  };

  const editImageUrl = (url: string) => {
    setImageUrl(url);
  };


  const deletePageHandler = (e: any) => {
    e.stopPropagation();
    if (window.confirm("¿Borrar esta pantalla?")) {
      DeleteExerciseAPI(
        exercises[selectedExercise].id,
        selectedExercise,
        props.research_id,
        null
      ).then((data) => {
        setExercises(data.exercises);
        setSelectedExercise(0);
      });
    }
  };

  const updateExerciseHandler = async (new_exercise_data: ExerciseType) => {
    var tempExercises = [...exercises];
    const newExercise = await UpdateExerciseAPI(
      new_exercise_data.id,
      new_exercise_data
    );
    tempExercises[selectedExercise] = newExercise;
    setExercises(tempExercises);
  };

  const renderExercise = () => {
    const exercise = exercises[selectedExercise];
    if (exercise == null || exercise.text == null) return;
    switch (exercise.type.toLowerCase()) {
      case "choosemany":
        return (
          <ChooseManyEdit
            exercise={exercise}
            updateChooseMany={(newChooseMany: ExerciseType) =>
              updateExerciseHandler(newChooseMany)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "chooseone":
        return (
          <ChooseOneEdit
            exercise={exercise}
            updateChooseOne={(newChooseOne: ExerciseType) =>
              updateExerciseHandler(newChooseOne)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "definition":
        return (
          <DefinitionEdit
            exercise={exercise}
            updateDefinition={(newDefinition: ExerciseType) =>
              updateExerciseHandler(newDefinition)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "theory":
        return (
          <TheoryEdit
            exercise={exercise}
            updateTheory={(newTheory: ExerciseType) =>
              updateExerciseHandler(newTheory)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "truefalse":
        return (
          <TrueFalseEdit
            exercise={exercise}
            selectedExercise={selectedExercise}
            updateTrueFalse={(newTrueFalse: ExerciseType) =>
              updateExerciseHandler(newTrueFalse)
            }
          />
        );

      case "writeanswers":
        return (
          <WriteAnswersEdit
            type={"research"}
            exercise={exercise}
            x={Math.random()}
            updateWriteAnswers={(newWriteAnswers: ExerciseType) =>
              updateExerciseHandler(newWriteAnswers)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "dragdroplist":
        return (
          <DragDropListEdit
            exercise={exercise}
            updateDragDropList={(newDragDropList: ExerciseType) =>
              updateExerciseHandler(newDragDropList)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "dragdroptwolists":
        return (
          <DragDropTwoListsEdit
            exercise={exercise}
            updateDragDropTwoLists={(newDragDropTwoLists: ExerciseType) =>
              updateExerciseHandler(newDragDropTwoLists)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "dragdroparrows":
        return (
          <DragDropArrowsEdit
            exercise={exercise}
            updateDragDropArrows={(newDragDropArrows: ExerciseType) =>
              updateExerciseHandler(newDragDropArrows)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "dragdropoptions":
        return (
          <DragDropOptionsEdit
            exercise={exercise}
            updateDragDropOptions={(newDragDropOptions: ExerciseType) =>
              updateExerciseHandler(newDragDropOptions)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "fillinthegaps":
        return (
          <FillInTheGapsEdit
            exercise={exercise}
            updateFillInTheGaps={(newFillInTheGaps: ExerciseType) =>
              updateExerciseHandler(newFillInTheGaps)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "media":
        return (
          <MediaEdit
            exercise={exercise}
            updateMedia={(newMedia: ExerciseType) =>
              updateExerciseHandler(newMedia)
            }
            selectedExercise={selectedExercise}
          />
        );

      default:
        console.log("Error?");
        return <></>;
    }
  };

  const renderTheoryIcon = (exerciseIndex: number) => {
    return (
      <div
        key={exerciseIndex}
        className={
          "navbar_level navbar_element navbar_textbox" +
          (selectedExercise == exerciseIndex ? " selected" : "")
        }
      >
        <motion.li
          className="center_container"
          layout
          style={
            selectedExercise == exerciseIndex
              ? {
                backgroundColor: props.subject.color + "80",
                width: "100%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }
              : {
                backgroundColor: props.subject.color + "40",
                // width: "100%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }
          }
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 10px 1px " + props.subject.color + "20",
          }}
          animate={
            selectedExercise === exerciseIndex
              ? { backgroundColor: props.subject.color + "80" }
              : {
                backgroundColor: props.subject.color + "40",
                transition: { duration: 1.5 },
              }
          }
          onClick={() => {
            navbarClickHandler(exerciseIndex);
          }}
        >
          {/* <img
          src={selectedLevel == 0 ? TextBoxWhiteIcon : TextBoxIcon}
          style={{ scale: "0.5" }}
        /> */}
          <TheoryIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={24}
          />
        </motion.li>
      </div>
    );
  };

  const renderMediaIcon = (exerciseIndex: number) => {
    return (
      <div
        key={exerciseIndex}
        className={
          "navbar_level navbar_element navbar_textbox" +
          (selectedExercise == exerciseIndex ? " selected" : "")
        }
      >
        <motion.li
          className="center_container"
          layout
          style={
            selectedExercise == exerciseIndex
              ? {
                backgroundColor: props.subject.color + "80",
                width: "100%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }
              : {
                backgroundColor: props.subject.color + "40",
                // width: "100%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }
          }
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 10px 1px " + props.subject.color + "20",
          }}
          animate={
            selectedExercise === exerciseIndex
              ? { backgroundColor: props.subject.color + "80" }
              : {
                backgroundColor: props.subject.color + "40",
                transition: { duration: 1.5 },
              }
          }
          onClick={() => {
            navbarClickHandler(exerciseIndex);
          }}
        >
          {/* <img
          src={selectedLevel == 0 ? TextBoxWhiteIcon : TextBoxIcon}
          style={{ scale: "0.5" }}
        /> */}
          <MediaIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={24}
          />
        </motion.li>
      </div>
    );
  };

  const renderDefinitionIcon = (exerciseIndex: number) => {
    return (
      <div
        key={exerciseIndex}
        className={
          "navbar_level navbar_element navbar_textbox" +
          (selectedExercise == exerciseIndex ? " selected" : "")
        }
      >
        <motion.li
          className="center_container"
          layout
          style={
            selectedExercise == exerciseIndex
              ? {
                backgroundColor: props.subject.color + "80",
                width: "100%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }
              : {
                backgroundColor: props.subject.color + "40",
                width: "100%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }
          }
          onClick={() => {
            navbarClickHandler(exerciseIndex);
          }}
          whileHover={{
            scale: 1.02,
            boxShadow: "0px 0px 10px 1px " + props.subject.color + "20",
          }}
          animate={
            selectedExercise === exerciseIndex
              ? { backgroundColor: props.subject.color + "80" }
              : {
                backgroundColor: props.subject.color + "40",
                transition: { duration: 1.5 },
              }
          }
        >
          {/* <img
            src={
              selectedLevel == levels?.length - 1
                ? FinishFlagWhiteIcon
                : FinishFlagIcon
            }
            style={{ scale: "0.5" }}
          /> */}
          <DefinitionIcon
            color={selectedExercise == exerciseIndex ? "white" : "black"}
            size={24}
          />
        </motion.li>
      </div>
    );
  };

  const renderExerciseIcon = (level: number, exerciseIndex: number) => {
    return (
      <div
        key={exerciseIndex}
        className={
          "navbar_element navbar_level" +
          (selectedExercise == exerciseIndex ? " selected" : "")
        }
      >
        <motion.li
          className="navbar_level_number"
          onClick={() => {
            navbarClickHandler(exerciseIndex);
          }}
          layout
          whileHover={{
            scale: 1.02,
            boxShadow:
              selectedExercise == exerciseIndex
                ? "0px 0px 10px 1px " + props.subject.color + "40"
                : "0px 0px 10px 1px " + props.subject.color + "20",
          }}
          animate={
            selectedExercise == exerciseIndex
              ? {
                backgroundColor: props.subject.color + "80",
              }
              : {
                backgroundColor: props.subject.color + "40",
                transition: { duration: 1.5 },
              }
          }
          style={
            selectedExercise == exerciseIndex
              ? {
                backgroundColor: props.subject.color + "80",
                width: "100%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }
              : {
                backgroundColor: props.subject.color + "40",
                width: "100%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }
          }
        >
          <div className="center_container">{level}</div>
          {selectedExercise === exerciseIndex ? (
            <motion.div
              className="navbar_selected_level_background"
              layoutId="background"
              style={{
                backgroundColor: props.subject.color + "60",
              }}
            />
          ) : null}
        </motion.li>
      </div>
    );
  };



  const researchNavbar = () => {
    if (exercises !== null && exercises !== undefined && exercises.length > 0) {
      var levelIndex = 1;
      return (
        <div className="exercise_preview_navbar_container">
          <div
            className="navbar_element navbar_back_icon"
            onClick={() => props.backHandler()}
          >
            <motion.div
              className="center_container"
              whileHover={{ scale: 1.4 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              <BackArrow color={props.subject.color} size={"45"} />
            </motion.div>
          </div>
          <ul
            className="navbar_level_container"
            style={{
              gridTemplateColumns:
                "repeat(" +
                exercises.length +
                ", " +
                100 / exercises.length +
                "%)",
              backgroundColor: "white",
              listStyleType: "none",
            }}
          >
            {exercises?.map((exercise: any, exerciseIndex: number) => {
              if (exercise.type === "theory") {
                return renderTheoryIcon(exerciseIndex);
              } else if (exercise.type === "definition") {
                return renderDefinitionIcon(exerciseIndex);
              } else if (exercise.type === "media") {
                return renderMediaIcon(exerciseIndex);
              } else {
                var copyLevelIndex = levelIndex;
                levelIndex++;
                return renderExerciseIcon(copyLevelIndex, exerciseIndex);
              }
            })}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="exercise_preview_navbar_container">
          <div
            className="navbar_element navbar_back_icon"
            onClick={() => props.backHandler()}
          >
            <motion.div
              className="center_container"
              whileHover={{ scale: 1.4 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              <BackArrow color={props.subject.color} size={"45"} />
            </motion.div>
          </div>
          <div
            className="navbar_element navbar_level"
            style={{
              backgroundColor: props.subject.color + "80",
              width: "100%",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          />
        </div>
      );
    }
  };

  if (openExerciseSelector) {
    return (
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
        }}
      >
        {researchNavbar()}
        <ExerciseSelectorPage
          updateScreen={(
            exercise_type:
              | "theory"
              | "truefalse"
              | "choosemany"
              | "chooseone"
              | "dragdropoptions"
              | "fillinthegaps"
              | "writeanswers"
              | "dragdroparrows"
              | "dragdroplist"
              | "dragdroptwolists"
              | "definition"
              | "media"
          ) => createExerciseHandler(exercise_type)}
          type={"research"}
        />
      </div>
    );
  }

  if (exercises == null) {
    return <div></div>;
  }

  return (
    <div
      className="exercise_preview_main"
      style={{
        boxShadow: "0px 0px 10px 2px " + props.subject.color + "20",
      }}
    >
      {researchNavbar()}
      <div>{renderExercise()}</div>

      <motion.div
        className="add_level_button"
        onClick={() => {
          setOpenExerciseSelector(true);
        }}
        whileHover={{
          scale: 1.08,
          boxShadow: "0px 0px 10px 1px #5090F0",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_small_bold">{t("add_exercise")}</div>
        </div>
      </motion.div>

      <motion.div
        className="delete_button"
        onClick={(e) => {
          deletePageHandler(e);
        }}
        whileHover={{
          scale: 1.08,
          boxShadow: "0px 0px 10px 1px #F0684F",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_small_bold">{t("delete_exercise")}</div>
        </div>
      </motion.div>

    </div>
  );
};
export default ExercisePreviewResearch;
