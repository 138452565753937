import "./Marketplace.css";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useState, useEffect, ChangeEvent } from "react";
import { LazyMotion, m, motion } from "framer-motion";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import { menu } from "../../../reducers/teacherSlice";
import { Input, HStack } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import Plus from "../../../assets/professor/plus.tsx";
import Loading from "../../loading_screen/LoadingDots.tsx";
import UnitMenu from "./UnitMenu.tsx";
import UserMenu from "../UserMenu/UserMenu.tsx";

import {
  CreateUnitAPI,
  GetAllUnitsAPI,
  ModifiedUnitType,
} from "../../../_newapios/content/unit.ts";
import { RegulationType } from "../../../_newapios/content/regulation.ts";
import {
  BundleType,
  GetClassBundlesAPI,
} from "../../../_newapios/user/class.ts";
import { useSelector } from "react-redux";
import { CourseType } from "../../../_newapios/content/course.ts";
import * as SCORM_API_wrapper from "../../../_newapios/scorm/SCORM_API_wrapper.js";
import _ from "lodash";
import { render } from "@react-pdf/renderer";

const Marketplace = ({ ...props }) => {
  const teacherState = useAppSelector((state) => state.teacher);
  const mainState = useAppSelector((state) => state.main);
  const loginState = useAppSelector((state) => state.login);

  const { t, i18n } = useTranslation(["common", "evaluation"]);
  const coursesList = mainState.courses;

  const regulations = mainState.regulations;
  const authors = teacherState.authors;

  const authorOptions = [
    {
      value: 0,
      label: t("me"),
      colorScheme: "yellow",
    },
    {
      value: 1,
      label: t("my_school"),
      colorScheme: "green",
    },
    {
      value: 2,
      label: t("eutopia"),
      colorScheme: "blue",
    },
    {
      value: 3,
      label: t("others"),
      colorScheme: "gray",
    },
  ];

  const languages = [
    { value: "es", label: t("spanish") },
    { value: "en", label: t("english") },
    { value: "val", label: t("valencian") },
    { value: "cat", label: t("catalonian") },
    { value: "de", label: t("deutsch") },
    { value: "fr", label: t("french") },
  ];

  var scorm =
    //@ts-ignore
    SCORM_API_wrapper.SCORM; // The variable scorm is a shortcut that helps reduce typing
  // console.log(scorm)
  var lmsConnected = false;

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<
    string | number | readonly string[] | undefined
  >();
  const [regulationValue, setRegulationValue] = useState<string[]>(
    teacherState.selectedClass?.regulation_id !== undefined
      ? [teacherState.selectedClass?.regulation_id]
      : []
  );
  const [subjectValue, setSubjectValue] = useState<
    {
      value: string;
      id: string;
      label: string;
      color: string;
      colorScheme: string;
      regulation_id: string;
    }[]
  >();
  const defaultAuthor = authorOptions.find((author) => author.value === 2);

  const [authorValue, setAuthorValue] = useState<
    {
      value: number;
      label: string;
      colorScheme?: string;
    }[]
  >(defaultAuthor ? [defaultAuthor] : []);
  const [languageValue, setLanguageValue] = useState<typeof languages>([]);
  const [loading, setLoading] = useState(true);
  const [unitsList, setUnitsList] = useState<ModifiedUnitType[]>([]);
  const [bundles, setBundles] = useState<BundleType[]>();
  const [openUnitMenu, setOpenUnitMenu] = useState(false);
  const [openUnitEditMenu, setOpenUnitEditMenu] = useState(false);
  const [openExerciseEditMenu, setOpenExerciseEditMenu] = useState<
    null | "research" | "experiment"
  >(null);
  const [selectedUnit, setSelectedUnit] = useState<ModifiedUnitType>();
  const [selectedCourse, setSelectedCourse] = useState<CourseType>();

  // Filter courses based on regulation_id

  const getCoursesByRegulation = (regulationIds: string[]) => {
    if (!regulationIds || regulationIds.length === 0) return coursesList;
    return coursesList.filter((course) =>
      regulationIds.includes(course.regulation_id)
    );
  };

  const mappedSubjects = getCoursesByRegulation(regulationValue).map(
    (course) => ({
      ...course,
      label: t(course.acronym + "_name", { ns: "evaluation" }),
      value: course.id,
      colorScheme: course.color,
    })
  );

  useEffect(() => {
    loadPreliminaryData();
  }, []);

  const loadPreliminaryData = () => {
    setLoading(true);
    if (teacherState.selectedClass === null) {
      return;
    }

    var promises = [
      GetAllUnitsAPI().then((units) => setUnitsHandler(units)),
      GetClassBundlesAPI(teacherState.selectedClass?.id).then((bundles) =>
        setBundleHandler(bundles)
      ),
    ];
    Promise.all(promises).then(() => setLoading(false));
  };

  const setUnitsHandler = (topics: any) => {
    setUnitsList(topics);
  };

  const setBundleHandler = (bundles: BundleType[]) => {
    setBundles(bundles);
  };

  const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const regulationHandler = (value: any) => {
    const regs = value as {
      label: string;
      value: string;
    }[];
    setRegulationValue(regs.map((reg) => reg.value));
  };

  const subjectHandler = (value: any) => {
    console.log(value);
    setSubjectValue(value);
  };

  const authorHandler = (value: any) => {
    setAuthorValue(value);
  };

  const languageHandler = (value: any) => {
    setLanguageValue(value);
  };

  const unitHandler = (unit: ModifiedUnitType, course: CourseType) => {
    setSelectedUnit(unit);
    setSelectedCourse(course);
    setOpenUnitMenu(true);
  };

  const handleError = (msg: String) => {
    // alert(msg);
    // window.close();
    console.warn(msg);
  };

  const initCourse = () => {
    //scorm.init returns a boolean
    lmsConnected =
      //@ts-ignore
      scorm.init();

    //If the scorm.init function succeeded...
    if (lmsConnected) {
      //Let's get the completion status to see if the course has already been completed
      var completionstatus = scorm
        //@ts-ignore
        .get("cmi.core.lesson_status");
      console.log(completionstatus);

      //If the course has already been completed...
      if (completionstatus == "completed" || completionstatus == "passed") {
        //...let's display a message and close the browser window
        handleError(
          "You have already completed this course. You do not need to continue."
        );
      }

      //If the course couldn't connect to the LMS for some reason...
    } else {
      //... let's alert the user then close the window.
      handleError("Error: Course could not connect with the LMS");
    }
  };

  const filterSearchUnits = (unit: ModifiedUnitType) => {
    if (searchValue) {
      if (
        unit.name.toLowerCase().includes(searchValue.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const filterSubjectUnits = (unit: ModifiedUnitType) => {
    var isSubject = true;
    if (subjectValue !== undefined && subjectValue.length > 0) {
      isSubject = false;
      for (var i = 0; i < subjectValue?.length; i++) {
        if (unit.course_id === subjectValue[i].id) {
          isSubject = true;
        }
      }
    }
    return isSubject;
  };

  const filterAuthorUnits = (unit: ModifiedUnitType) => {
    var isAuthor = true;
    if (authorValue !== undefined && authorValue.length > 0) {
      isAuthor = false;
      for (var i = 0; i < authorValue.length; i++) {
        switch (authorValue[i].value) {
          case 0:
            if (unit.owner?.id === loginState.id) {
              isAuthor = true;
            }
            break;
          case 1:
            if (unit.owner?.school_id === teacherState.userLicense?.school_id) {
              isAuthor = true;
            }
            break;
          case 2:
            if (
              unit.owner?.school_id === "school_id" ||
              unit.owner?.school_id === "47ae1732-81fd-4e47-b25b-ea6c4b64c629"
            ) {
              isAuthor = true;
            }
            break;
          case 3:
            if (
              unit.owner?.id !== loginState.id &&
              unit.owner?.school_id !== teacherState.userLicense?.school_id &&
              (unit.owner?.school_id !== "school_id" ||
                unit.owner?.school.id !==
                "47ae1732-81fd-4e47-b25b-ea6c4b64c629")
            ) {
              isAuthor = true;
            }
            break;
        }
      }
    }
    return isAuthor;
  };

  const filterLanguageUnits = (unit: ModifiedUnitType) => {
    if (languageValue.length !== 0) {
      if (
        languageValue.filter(
          (lang) => lang.value.toLowerCase() === unit.language.toLowerCase()
        ).length !== 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const filterRegulationUnits = (unit: ModifiedUnitType) => {
    var isRegulation = true;
    if (regulationValue !== undefined && regulationValue.length > 0) {
      isRegulation = false;
      for (var i = 0; i < regulationValue.length; i++) {
        if (unit.regulation_id === regulationValue[i]) {
          isRegulation = true;
        }
      }
    }
    return isRegulation;
  };

  const createUnitHandler = async () => {
    const selectedClass = teacherState.selectedClass;
    const regulationId = selectedClass?.regulation_id;
    const newUnit = await CreateUnitAPI(regulationId || "");

    var newUnits = [...unitsList];
    newUnits.push(newUnit);
    setUnitsList(newUnits);
    setSelectedUnit(newUnit);

    var unitSubject = coursesList.find(
      (subject) => subject.id === newUnit.course_id
    );
    setSelectedCourse(unitSubject);
    setOpenUnitMenu(true);
    setOpenUnitEditMenu(true);
  };

  const unitBackHandler = (lmao: string) => {
    setOpenUnitMenu(false);
    setOpenUnitEditMenu(false);
    loadPreliminaryData();
  };

  const renderBack = () => {
    return (
      <div className="professor_marketplace_top">
        <div className="left_container" style={{ width: "65%", justifyContent: "space-between" }} >
          <LazyMotion features={loadFeatures}>
            <m.div
              className="professor_marketplace_title"
              whileHover={{
                scale: 1.05,
                textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
              }}
              transition={{ type: "tween", duration: 0.2 }}
              onClick={() => dispatch(menu("menus"))}
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              <BackArrow color={"#000000"} size={"8vh"} />
              <div className="text_black_big_bold">{t("go_back")}</div>
            </m.div>
          </LazyMotion>
          <div className="text_black_big_bold">{t("situations_library")}</div>
        </div>
        <UserMenu color={"black"} />
      </div>
    );
  };

  const renderFilters1 = () => {
    return (
      <HStack spacing={8}>
        <Input
          placeholder={t("search_placeholder")}
          size="lg"
          style={{ fontFamily: "Causten" }}
          as={motion.input}
          type="search"
          value={searchValue}
          onChange={searchHandler}
          borderRadius={"3px"}
          w={"20vw"}
          minW={"15vw"}
          whileHover={{ scale: 1.01, boxShadow: "0px 0px 10px 1px #5090F0" }}
          transition="0.1s ease"
        />

        <Select
          isMulti
          name="subjects"
          useBasicStyles
          options={mappedSubjects}
          placeholder={t("subjects_placeholder")}
          closeMenuOnSelect={false}
          onChange={subjectHandler}
          variant="outline"
          value={subjectValue}
          chakraStyles={{
            control: (provided) => ({
              ...provided,
              minWidth: "15vw",
              fontFamily: "Causten",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            multiValue: (provided, state) => {
              return {
                ...provided,
                borderRadius: "50px",
                color: "white",
                background: state.data.color,
              };
            },
            clearIndicator: (provided) => ({
              ...provided,
              color: "#F0684F",
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: "white",
              color: regulations.find(
                (reg) => reg.id === state.data.regulation_id
              )?.color,
              fontFamily: "Causten",
              fontWeight: "bold",
            }),
          }}
        />

        <Select
          isMulti
          name="languages"
          useBasicStyles
          options={languages}
          placeholder={t("languages_placeholder")}
          closeMenuOnSelect={false}
          onChange={languageHandler}
          value={languageValue}
          chakraStyles={{
            multiValue: (provided) => ({
              ...provided,
              borderRadius: "50px",
            }),
            control: (provided) => ({
              ...provided,
              minWidth: "15vw",
              fontFamily: "Causten",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            clearIndicator: (provided) => ({
              ...provided,
              color: "#F0684F",
            }),
          }}
        />
      </HStack>
    );
  };

  const renderFilters2 = () => {
    return (
      <HStack spacing={8}>
        <Select
          isMulti
          name="authors"
          useBasicStyles
          options={authorOptions}
          placeholder={t("authors_placeholder")}
          closeMenuOnSelect={false}
          onChange={authorHandler}
          value={authorValue}
          chakraStyles={{
            multiValue: (provided) => ({
              ...provided,
              borderRadius: "50px",
            }),
            control: (provided) => ({
              ...provided,
              minWidth: "15vw",
              fontFamily: "Causten",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            clearIndicator: (provided) => ({
              ...provided,
              color: "#F0684F",
            }),
          }}
        />
        <Select
          isMulti
          name="regulations"
          options={regulations.map((reg) => {
            return {
              label: t(reg.name, { ns: "evaluation" }),
              value: reg.id,
            };
          })}
          useBasicStyles
          placeholder={t("regulation_placeholder")}
          closeMenuOnSelect={false}
          onChange={regulationHandler}
          value={regulationValue.map((regId) => {
            return {
              label: t(
                regulations.find((reg) => reg.id === regId)?.name ||
                "No labels?",
                { ns: "evaluation" }
              ),
              value: regId,
            };
          })}
          chakraStyles={{
            multiValue: (provided, state) => ({
              ...provided,
              borderRadius: "50px",
              color: "white",
              backgroundColor: regulations.find(
                (reg) => reg.id === state.data.value
              )?.color,
            }),
            control: (provided) => ({
              ...provided,
              minWidth: "15vw",
              fontFamily: "Causten",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            clearIndicator: (provided) => ({
              ...provided,
              color: "#F0684F",
            }),
          }}
        />
      </HStack>
    );
  };

  const renderCreateUnit = () => {
    return (
      <motion.div
        className="professor_marketplace_add_content"
        whileHover={{ scale: 1.04, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        onClick={() => createUnitHandler()}
      >
        <div className="center_container">
          <Plus color={"#5090F0"} />
          <div className="text_blue_medium_bold">{t("create_block")}</div>
        </div>
      </motion.div>
    );
  };

  const renderUnit = (unit: ModifiedUnitType) => {
    if (
      filterSearchUnits(unit) &&
      filterSubjectUnits(unit) &&
      filterLanguageUnits(unit) &&
      filterRegulationUnits(unit) &&
      filterAuthorUnits(unit)
    ) {
      var unitSubject =
        coursesList.find((subject) => subject.id === unit.course_id) ||
        coursesList[0];
      var unitRegulation = regulations.find(
        (regulation) => regulation.id === unit.regulation_id
      );
      // var isPartOfBundle = bundle?.find((bundleUnit: any) => bundleUnit.unit_id === unit.id);  // Guess its not needed anymore
      return (
        <motion.div
          className="professor_marketplace_content"
          key={unit.id}
          whileHover={{
            scale: 1.04,
            boxShadow: "0px 0px 10px 2px " + unitSubject?.color,
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          onClick={() => unitHandler(unit, unitSubject)}
        >
          <img
            className="banner"
            src={
              _.isUndefined(unit.banner)
                ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Tutorial/Tutorial+-+Banner.jpg"
                : unit.banner
            }
            alt="banner"
          />
          <div className="professor_marketplace_content_mid_name">
            {/* <div className="professor_matketplace_content_mid_left"> */}
            <div
              className="text_black_really_small_bold"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }} // TODO - copy this elsewhere
            >
              {(unit.name !== null || unit.name !== "") && unit.name}
            </div>
          </div>
          <div className="professor_marketplace_content_mid_rest">
            <div className="text_light_grey_super_small">
              {
                languages.find(
                  (lang) => lang.value === unit.language.toLowerCase()
                )?.label
              }
            </div>
            <div
              className="professor_marketplace_content_mid_subject"
              style={{
                background: unitSubject?.color,
              }}
            >
              <div className="center_container">
                <div className="text_white_super_small">
                  {t(unitSubject?.acronym + "_short", { ns: "evaluation" })}
                </div>
              </div>
            </div>
          </div>
          <div className="professor_marketplace_content_bot">
            <div className="text_black_super_small">
              {t("created_by") + " "}
              <b>{unit.owner?.fullname}</b>
            </div>
            <div>
              <div
                className="professor_marketplace_content_bot_regulation"
                style={{
                  backgroundColor: unitRegulation?.color,
                }}
              >
                <div className="center_container">
                  <div className="text_white_hiper_small">
                    {t(unitRegulation?.name || "ESO_name", {
                      ns: "evaluation",
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      );
    }
  };

  const renderUnits = () => {
    let unitsToDisplay = 0;
    unitsList.map((unit) => {
      if (
        unit != undefined &&
        unit.course_id !== "" &&
        filterSearchUnits(unit) &&
        filterSubjectUnits(unit) &&
        filterLanguageUnits(unit) &&
        filterRegulationUnits(unit) &&
        filterAuthorUnits(unit)
      ) {
        unitsToDisplay += 1;
      }
    });
    return (
      <div
        className="professor_marketplace_container"
        style={{
          gridTemplateRows:
            "repeat(" + Math.ceil((unitsToDisplay + 1) / 4) + ", 25%",
        }}
      >
        {renderCreateUnit()}
        {unitsList.map((unit) => {
          return renderUnit(unit);
        })}
      </div>
    );
  };

  const renderBackUnit = () => {
    return (
      <div className="professor_marketplace_top">
        <div className="left_container" style={{ width: "65%", justifyContent: "space-between" }} >
          <LazyMotion features={loadFeatures}>
            <m.div
              className="professor_marketplace_title"
              whileHover={{
                scale: 1.05,
                textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
              }}
              transition={{ type: "tween", duration: 0.2 }}
              onClick={() => unitBackHandler("none")}
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              <BackArrow color={"#000000"} size={"8vh"} />
              <div className="text_black_big_bold">
                {t("go_back")}

              </div>
            </m.div>
          </LazyMotion>
        </div>
        <UserMenu color={"black"} />
      </div>
    )
  };

  return (
    <div className={loading ? "center_container" : "professor_marketplace"}>
      {loading && <Loading />}
      {!loading && (!openUnitMenu ? renderBack() : renderBackUnit())}
      {!loading && !openUnitMenu && (
        <div className="professor_marketplace_body">
          {renderFilters1()}
          {renderFilters2()}
          {renderUnits()}
        </div>
      )}
      {!loading && openUnitMenu && openExerciseEditMenu === null && (
        <UnitMenu
          unit={selectedUnit}
          subjects={coursesList}
          bundle={undefined}
          openUnitEditMenu={openUnitEditMenu}
          changeUnitHandler={(unit: ModifiedUnitType) => setSelectedUnit(unit)}
          closeFakeUnitEditMenu={() => setOpenUnitEditMenu(false)}
          backHandler={(lmao: string) => unitBackHandler(lmao)}
        />
      )}
    </div>
  );
};

export default Marketplace;
