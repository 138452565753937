import "./ChooseMany.css";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";
import {
  Arrows,
  Background,
  ConfirmButton,
  ExerciseImage,
  ExerciseName,
  HelpMenu,
  Professor,
  TextBox,
} from "./_components";

const ChooseMany = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const image = props.exercise.image?.url;
  const animationUrl = props.exercise.animation?.url;
  const solutions = props.exercise.solutions as number[];
  const options = props.exercise.options as string[];

  const [selectedExercise, setselectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setmaxExercise] = useState(props.maxExercise);
  const [selected, setSelected] = useState([] as number[]);
  const [shuffledOptions, setShuffledOptions] = useState([] as string[]);

  const [research, setResearch] = useState(props.research);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    setSelected(_.fill(Array(solutions.length), 0));
    setShuffledOptions(_.shuffle(options));
  }, [props.exercise.id]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    correctAnswer();
  }, [selectedExercise, maxExercise]);

  useEffect(() => {
    setselectedExercise(props.exerciseIndex);
    setmaxExercise(props.maxExercise);
  }, [props.exerciseIndex]);

  const correctAnswer = () => {
    if (research) {
      if (selectedExercise < maxExercise) {
        setSelected(solutions);
        setShuffledOptions(options);
      }
    }
  };

  useEffect(() => {
    setResearch(props.research);
  }, [props.research]);

  useEffect(() => {
    if (image !== undefined || animationUrl !== undefined) {
      setImageExists(true);
    }
  }, [image, animationUrl]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const confirmHandler = () => {
    var correct = true;
    var mySolution = [...solutions];
    var mySelected = [...selected];
    var correctOptions: string[] = [];
    var answeredOptions: string[] = [];

    for (let i = 0; i < mySolution.length; i++) {
      var optIndex = mySolution.findIndex((element) => element === 1); // correct solutions are when value is 1 (true)
      if (optIndex !== -1) {
        correctOptions.push(options[optIndex]);
        mySolution[optIndex] = 0;
      }
      var ansIndex = mySelected.findIndex((element) => element === 1); // index of our selected answers
      if (ansIndex !== -1) {
        answeredOptions.push(shuffledOptions[ansIndex]);
        mySelected[ansIndex] = 0;
      }
    }

    if (correctOptions.length !== answeredOptions.length) {
      correct = false; // you didn't take as many options as the one that were correct
    } else {
      for (let i = 0; i < correctOptions.length; i++) {
        var containsAnswer = answeredOptions.includes(correctOptions[i]);
        if (!containsAnswer) {
          correct = false;
        }
      }
    }
    if (research) {
      if (correct) {
        props.updateLevel("right", answeredOptions, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        setIncorrectFeedback(true);
        props.wrongAnswerSound();
      }
    } else {
      if (correct) {
        props.updateLevel("right", answeredOptions, true);
      } else {
        props.updateLevel("right", answeredOptions, false);
      }
    }
  };

  const optionButtonHandler = (index: number) => {
    var mySelected = [...selected];
    if (mySelected[index] === 1) {
      mySelected[index] = 0;
    } else {
      mySelected[index] = 1;
    }
    setSelected(mySelected);
  };

  const renderOptionsButtons = () => {
    return (
      <div
        className="choose_many_options"
        style={{
          gridTemplateColumns:
            "repeat(" + selected.length + ", " + 100 / selected.length + "%)",
        }}
      >
        {selected.map((selection, selectionIndex) => {
          if (selected.length !== shuffledOptions.length) return null;
          return (
            <motion.div
              key={selectionIndex}
              className={
                selection === 1
                  ? "choose_many_option_pressed"
                  : "choose_many_option"
              }
              onClick={() => optionButtonHandler(selectionIndex)}
              whileHover={{
                scale: 1.02,
                boxShadow: "0px 0px 10px 1px #5090F0",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <div className="center_container">
                <div
                  className={
                    selection === 1 &&
                    shuffledOptions[selectionIndex].length < 70
                      ? "text_white_small_bold"
                      : selection === 1 &&
                        shuffledOptions[selectionIndex].length >= 70
                      ? "text_white_very_small_bold"
                      : selection === 0 &&
                        shuffledOptions[selectionIndex].length < 70
                      ? "text_black_small_bold"
                      : "text_black_very_small_bold"
                  }
                  style={{ padding: "1%" }}
                >
                  {shuffledOptions[selectionIndex]}
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="choose_many">
      <Background
        incorrectFeedback={incorrectFeedback}
        setIncorrectFeedback={setIncorrectFeedback}
      />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={imageExists} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={research}
      />
      <ConfirmButton confirmHandler={confirmHandler} />
      <HelpMenu helpText={helpText} tipHandler={props.tipHandler} />
      <ExerciseImage
        image={image}
        animationUrl={animationUrl}
        imageExists={imageExists}
      />
      <ExerciseName type={props.exercise.type} smallMargin={imageExists} />
      {renderOptionsButtons()}
    </div>
  );
};

export default ChooseMany;
