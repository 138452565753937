import env from "../../config/env.json";
import { BundleType, ModifiedBundleType } from "../user/class";
import { TeacherType } from "../user/teacher";
import { ModifiedScenarioType } from "./scenario";

export interface ImageType {
  id: string;
  url: string;
  verified: boolean;
  owner_id: string;
}

export interface AnimationType {
  id: string;
  url: string;
  verified: boolean;
}

export interface GlobalCompetenceType {
  id: string;
  acronym: string;
  number: number;
  color: string;
}

export interface SpecificCompetenceType {
  id: string;
  number: number;
  course_id: string;
  course: {
    acronym: string;
  };
  global_competences: GlobalCompetenceType[];
}

export interface EvaluationCriterionType {
  id: string;
  number: number;
  specific_competence_id: string;
  specific_competence?: SpecificCompetenceType;
}

export interface ExerciseType {
  id: string;
  language: string;
  priority_index: number;
  type:
    | "theory"
    | "truefalse"
    | "choosemany"
    | "chooseone"
    | "dragdropoptions"
    | "fillinthegaps"
    | "writeanswers"
    | "dragdroparrows"
    | "dragdroplist"
    | "dragdroptwolists"
    | "definition"
    | "media"; // This allows to validate stuff

  text?: string;
  image_id?: string;
  image?: ImageType;
  animation_id?: string;
  animation?: AnimationType;
  help_text?: string;
  solutions?: number[];
  options?: string[];
  labels?: string[];
  max_error?: number[];
  main_text?: string;
  random_lims?: number[];
}

export interface ScenarioType {
  id: string;
  name: string;
  type: string;
  description: string;
  know_hows: string[];
  banner: ImageType;
  image: ImageType;
  research: {
    id: string;
  };
  experiment: {
    id: string;
  };
  evaluation_criteria_ids: string[];
}
export interface UnitType {
  id: string;
  name: string;
  language: string;
  visibility: number;
  owner_id: string;
  course_id: string;
  scenarios: ScenarioType[];
}

export interface ModifiedUnitType {
  id: string;
  name: string;
  language: string;
  visibility: number;
  owner_id: string;
  course_id: string;
  owner?: {
    //Teacher + school
    id: string;
    username: string;
    fullname: string;
    admin: boolean;
    school_id: string;
    school: {
      id: string;
      name: string;
      start_date: string;
      license_id: string | null;
    };
  };
  banner: string;
  scenario_count: number;
  regulation_id: string;
}

export const GetAllUnitsAPI = async (): Promise<ModifiedUnitType[]> => {
  var req = await fetch(env.backend_host + "/unit/all", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  data = data || [];
  return data;
};

export const GetUnitAPI = async (id: string): Promise<ModifiedUnitType> => {
  var req = await fetch(env.backend_host + "/unit/" + id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  return data;
};

export const UpdateUnitAPI = async (
  id: string,
  unit: ModifiedUnitType
): Promise<string | void> => {
  var req = await fetch(env.backend_host + "/unit/" + id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify(unit),
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();
  console.log(data);
  if (data.message !== null) {
    return data.message;
  }
};

export const GetUnitScenariosAPI = async (
  id: string
): Promise<ModifiedScenarioType[]> => {
  var req = await fetch(env.backend_host + "/unit/" + id + "/scenarios", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  data = data || [];
  return data;
};

export const GetUnitEvaluationCriteriaAPI = async (
  id: string
): Promise<EvaluationCriterionType[]> => {
  var req = await fetch(
    env.backend_host + "/unit/" + id + "/evaluation_criteria",
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
      mode: "cors",
      credentials: "include",
    }
  );
  var data = await req.json();

  data = data || [];
  return data;
};

export const CreateUnitAPI = async (
  regulation_id: string
): Promise<ModifiedUnitType> => {
  var req = await fetch(env.backend_host + "/unit", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify({ regulation_id }),
    mode: "cors",
    credentials: "include",
  });
  var data: ModifiedUnitType = await req.json();

  return data;
};

export const CloneUnitAPI = async (
  existing_unit_id: string
): Promise<ModifiedUnitType | null> => {
  var req = await fetch(
    env.backend_host + "/unit/" + existing_unit_id + "/clone",
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      mode: "cors",
      credentials: "include",
    }
  );

  var data = await req.json();

  data = data || null;

  return data;
};

export const AllBundlesofUnitAPI = async (
  unit_id: string,
  class_id: string
): Promise<BundleType[] | null> => {
  const req = await fetch(
    env.backend_host + "/unit/" + unit_id + "/bundles/" + class_id,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "GET",
      mode: "cors",
      credentials: "include",
    }
  );

  var data = await req.json();

  data = data || null;
  return data;
};

export const DeleteUnitAPI = async (id: string): Promise<void> => {
  var req = await fetch(env.backend_host + "/unit/delete/" + id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    mode: "cors",
    credentials: "include",
  });
  var data = req.status;
};

export const GetAllUnitsGPTAPI = async (): Promise<UnitType[]> => {
  var req = await fetch(env.backend_host + "/unit/all/gpt", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  data = data || [];
  return data;
};
