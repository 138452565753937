import {
  OrbitControls,
  useAnimations,
  useCursor,
  useFBX,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import {
  Selection,
  Select,
  EffectComposer,
  Outline,
} from "@react-three/postprocessing";
import * as THREE from "three";
import {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  Suspense,
  useCallback,
} from "react";
import { updateInfoBar } from "../../../reducers/studentSlice.ts";
import { DreiGLTF } from "../../../interfaces.ts";
import { ThreeEvent, useFrame } from "@react-three/fiber";

import Parks from "./LocalComponents/Parks.tsx";
import StaticClickableAssets from "./LocalComponents/StaticClickableAssets.tsx";
import PuestosFeria from "./LocalComponents/PuestosFeria.tsx";
import Caminos from "./LocalComponents/Caminos.tsx";
import MejoraCaminos from "./LocalComponents/MejoraCaminos.tsx";
import MejorasEnergia from "./LocalComponents/MejorasEnergia.tsx";
import MejorasCasas from "./LocalComponents/MejorasCasas.tsx";

//Importing variants of the Local Map
import ModelJungle from "./LocalComponents/LocalVariants/Jungle/ModelJungle.tsx";
import StaticAssetsJungle from "./LocalComponents/LocalVariants/Jungle/StaticAssetsJungle.tsx";
import MejoraVegetacionJungle from "./LocalComponents/LocalVariants/Jungle/MejoraVegetacionJungle.tsx";
import StaticAssetsGrassland from "./LocalComponents/LocalVariants/Grassland/StaticAssetsGrassland.tsx";
import ModelGrassland from "./LocalComponents/LocalVariants/Grassland/ModelGrassland.tsx";
import MejoraVegetacionGrassland from "./LocalComponents/LocalVariants/Grassland/MejoraVegetacionGrassland.tsx";
import ModelBeach from "./LocalComponents/LocalVariants/Beach/ModelBeach.tsx";
import StaticAssetsBeach from "./LocalComponents/LocalVariants/Beach/StaticAssetsBeach.tsx";
import MejoraVegetacionBeach from "./LocalComponents/LocalVariants/Beach/MejoraVegetacionBeach.tsx";
import ModelSavannah from "./LocalComponents/LocalVariants/Savannah/ModelSavannah.tsx";
import StaticAssetsSavannah from "./LocalComponents/LocalVariants/Savannah/StaticAssetsSavannah.tsx";
import MejoraVegetacionSavannah from "./LocalComponents/LocalVariants/Savannah/MejoraVegetacionSavannah.tsx";
import ModelDesert from "./LocalComponents/LocalVariants/Desert/ModelDesert.tsx";
import StaticAssetsDesert from "./LocalComponents/LocalVariants/Desert/StaticAssetsDesert.tsx";
import MejoraVegetacionDesert from "./LocalComponents/LocalVariants/Desert/MejoraVegetacionDesert.tsx";
import ModelSnow from "./LocalComponents/LocalVariants/Snow/ModelSnow.tsx";
import StaticAssetsSnow from "./LocalComponents/LocalVariants/Snow/StaticAssetsSnow.tsx";
import MejoraVegetacionSnow from "./LocalComponents/LocalVariants/Snow/MejoraVegetacionSnow.tsx";
import ModelSwamp from "./LocalComponents/LocalVariants/Swamp/ModelSwamp.tsx";
import StaticAssetsSwamp from "./LocalComponents/LocalVariants/Swamp/StaticAssetsSwamp.tsx";
import MejoraVegetacionSwamp from "./LocalComponents/LocalVariants/Swamp/MejoraVegetacionSwamp.tsx";
import ModelMountain from "./LocalComponents/LocalVariants/Mountain/ModelMountain.tsx";
import StaticAssetsMountain from "./LocalComponents/LocalVariants/Mountain/StaticAssetsMountain.tsx";
import MejoraVegetacionMountain from "./LocalComponents/LocalVariants/Mountain/MejoraVegetacionMountain.tsx";
import ModelVolcano from "./LocalComponents/LocalVariants/Volcano/ModelVolcano.tsx";
import StaticAssetsVolcano from "./LocalComponents/LocalVariants/Volcano/StaticAssetsVolcano.tsx";
import MejoraVegetacionVolcano from "./LocalComponents/LocalVariants/Volcano/MejoraVegetacionVolcano.tsx";
import Pingüino from "../R3FAnimations/PruebaPingüino/Pingüino.tsx";
import ArcticFox from "../R3FAnimations/PruebaPingüino/ArcticFox.tsx";
import {
  GetAllPowerUpgradesAPI,
  PowerUpgradeType,
} from "../../../_newapios/gamecontent/power_upgrades.ts";
import {
  GetStudentPowerAllAPI,
  StudentPowerType,
} from "../../../_newapios/progress/student_power.ts";
import PruebaNPC from "../R3FAnimations/PruebaNPC/PruebaNPC.tsx";
import { NavMeshManager } from "../R3FAnimations/PruebaNPC/NavMeshManager/NavMeshManager.tsx";
import { useNavMesh } from "../R3FAnimations/PruebaNPC/hooks/useNavMesh.ts";
import { useInitialization } from "../R3FAnimations/PruebaNPC/hooks/useInitialization.ts";

const LocalMap = ({ ...props }) => {
  const controlsRef = useRef<any>(null);

  const studentState = useAppSelector((state) => state.student);
  const loginState = useAppSelector((state) => state.login);
  const infoBar = studentState.infoBar;
  const dispatch = useAppDispatch();

  const [hovered, hover] = useState(false);
  const [funkyMode, setFunkyMode] = useState(false);

  const [powerUpgrades, setPowerUpgrades] = useState<PowerUpgradeType[]>([]);
  const [studentPower, setStudentPower] = useState<StudentPowerType[]>([]);
  useEffect(() => {
    loadProgress();
  }, []);

  const loadProgress = async () => {
    const temp_power_upgrades = await GetAllPowerUpgradesAPI();
    setPowerUpgrades(temp_power_upgrades);
    const temp_student_power = await GetStudentPowerAllAPI(
      props.studentId == null ? loginState.id : props.studentId
    );
    setStudentPower(temp_student_power);
  };

  const [ostrichEgg, setOstrichEgg] = useState(false);
  const [ostrichEggCount, setOstrichEggCount] = useState(0);

  const {
    init,
    getScene,
    setScene,
    getCamera,
    setCamera,
    getRenderer,
    setRenderer,
    getEntityManager,
  } = useNavMesh();

  const { onVehicleInitialized } = useInitialization(init);

  useFrame(() => {
    // ORBIT CONTROLS
    const vectorPosition = controlsRef.current.target;
    if (vectorPosition.x > 25) {
      vectorPosition.x = 25;
    } else if (vectorPosition.x < -25) {
      vectorPosition.x = -25;
    }

    if (vectorPosition.z > 20) {
      vectorPosition.z = 20;
    } else if (vectorPosition.z < -20) {
      vectorPosition.z = -20;
    }
  });

  const resetOstrichEgg = () => {
    setOstrichEgg(false);
    setOstrichEggCount(0);
  };

  useEffect(() => {
    if (ostrichEggCount == 4) {
      setOstrichEgg(true);
      setTimeout(() => resetOstrichEgg(), 10000);
    }
  }, [ostrichEggCount]);

  const ostrichEggHandler = (number: number) => {
    for (let i = 0; i < 4; i++) {
      if (i == number - 1 && ostrichEggCount == i) {
        setOstrichEggCount(ostrichEggCount + 1);
        break;
      } else {
        setOstrichEggCount(0);
      }
    }
  };

  const GrimmFBX = () => {
    const grimmRef = useRef<THREE.Group>(null!);
    let fbx = useFBX("HN_GrimmChild_Anim_final.LP_96frames_baked.fbx");
    const fbxMesh = fbx.children[0] as THREE.Mesh;
    const susTexture = useTexture("GrimmchildTexture.png");
    susTexture.colorSpace = THREE.SRGBColorSpace;
    susTexture.flipY = true;
    const susMaterial = new THREE.MeshPhongMaterial({
      map: susTexture,
      emissiveMap: susTexture,
      emissive: new THREE.Color(0x000000),
    });
    fbxMesh.material = susMaterial;

    const grimmActions = useAnimations(
      fbx.animations,
      grimmRef as MutableRefObject<THREE.Group>
    );

    useEffect(() => {
      // if (startPingu){actionsPingu.actions["Salto"]?.play();}
      // actionsPingu.actions["Salto_iceberg"]?.play();
      grimmActions.actions[
        "Armature|Armature|Armature|ArmatureAction.001"
      ]?.play();
    }, [grimmActions]);

    useFrame((state, delta) => {
      if (ostrichEgg) {
        grimmRef.current.position.y += 1 * delta;
        grimmRef.current.rotation.y += 5 * delta;
      }
    });

    return (
      <group>
        <primitive
          ref={grimmRef}
          object={fbx}
          scale={0.01}
          position={[8.05, -1, 0.27]}
          rotation={[0, Math.PI, 0]}
        />
        {/* {mode && <PositionalAudio
                url="./FreeBird.m4a"
                distance={1}
                position={[0,0,0]} 
                loop
                autoplay
                {...props} // All THREE.PositionalAudio props are valid
                />} */}
      </group>
    );
  };

  const localAll = useGLTF(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Local_ALL.gltf"
  ) as DreiGLTF;
  const bakedAssetsTexture1 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-new2.png"
  );
  const bakedAssetsTexture2 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-specular.png"
  );
  var bakedAssetsTexture3 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/assets/color-atlas-emission.png"
  );
  bakedAssetsTexture1.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTexture1.flipY = false;
  bakedAssetsTexture2.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTexture2.flipY = false;
  bakedAssetsTexture3.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTexture3.flipY = false;

  if (funkyMode) {
    bakedAssetsTexture3 = bakedAssetsTexture1;
  }

  const bakedAssetsMaterial = new THREE.MeshPhongMaterial({
    map: bakedAssetsTexture1,
    emissiveMap: bakedAssetsTexture3,
    emissive: new THREE.Color(0xff0000),
    specularMap: bakedAssetsTexture2,
    emissiveIntensity: 8,
    specular: 0x666666,
    fog: true,
  });

  const bakedAssetsTextureH2 = useTexture(
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Mejoras_energ%C3%ADa_nuevas/color-atlas-new3+(1).png"
  );
  bakedAssetsTextureH2.colorSpace = THREE.SRGBColorSpace;
  bakedAssetsTextureH2.flipY = false;

  const bakedAssetsMaterialH2 = new THREE.MeshPhongMaterial({
    map: bakedAssetsTextureH2,
    emissiveMap: bakedAssetsTexture3,
    emissive: new THREE.Color(0xff0000),
    specularMap: bakedAssetsTexture2,
    emissiveIntensity: 8,
    specular: 0x666666,
    fog: true,
  });
  useCursor(
    hovered,
    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
    'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
  );

  const hideInfoBar = () => {
    var newInfoBar = { ...infoBar, display: "hidden" };
    dispatch(updateInfoBar(newInfoBar));
  };

  const hasReforestationUpgrade = () => {
    const reforestationUpgrade = powerUpgrades.find(
      (upg) => upg.name === "energy_upgrade_reforestation_name"
    );
    return (
      studentPower?.filter(
        (studPow) => studPow.power_upgrade_id === reforestationUpgrade?.id
      ).length > 0
    );
  };

  const hasMaterialUpgrade = () => {
    const materialUpgrade = powerUpgrades.find(
      (upg) => upg.name === "energy_upgrade_material_name"
    );
    return (
      studentPower?.filter(
        (studPow) => studPow.power_upgrade_id === materialUpgrade?.id
      ).length > 0
    );
  };

  return (
    <>
      {/* <fog
        attach="fog"
        args={
          studentState.co2 <= 210
            ? ["#ffffff", 1, 150]
            : studentState.co2 <= 600
            ? ["#a6a6a6", 1, 150]
            : studentState.co2 <= 1000
            ? ["#a6a6a6", 1, 150]
            : ["#949494", 1, 150]
        }
      />
      <color attach="background" args={[1, 1, 1]} /> */}

      <OrbitControls
        ref={controlsRef}
        target={[0, 0, 0]}
        panSpeed={1}
        enableDamping={false}
        autoRotateSpeed={0}
        enableRotate={false}
        dampingFactor={0}
        minDistance={9}
        maxDistance={props.maxDist}
        rotateSpeed={0}
        minPolarAngle={Math.PI / 4}
        maxPolarAngle={Math.PI / 4}
        maxAzimuthAngle={(3 * Math.PI) / 4}
        minAzimuthAngle={(3 * Math.PI) / 4}
        screenSpacePanning={false}
        mouseButtons-LEFT={THREE.MOUSE.PAN}
        mouseButtons-RIGHT={THREE.MOUSE.ROTATE}
        touches-ONE={THREE.TOUCH.PAN}
      />

      <directionalLight
        castShadow
        position={[35, 55, 30]}
        intensity={props.IntMult * 5}
        shadow-normalBias={0.04}
        color={0xfafafa}
        shadow-mapSize={[2048, 2048]}
      />
      <ambientLight color={0xe9e9e9} intensity={props.IntMult * 0.4} />

      {/* Does not change depending on location */}

      {ostrichEgg && <GrimmFBX />}

      {/* <NavMeshManager
        getEntityManager={getEntityManager}
        getCamera={getCamera}
        getRenderer={getRenderer}
        getScene={getScene}
      >
        <PruebaNPC onVehicleInitialized={onVehicleInitialized} />
      </NavMeshManager> */}

      <Parks
        GLTF={localAll}
        bakedAssetsMaterial={bakedAssetsMaterial}
        renderShadow={() => props.renderShadow()}
        ostrichEggHandler={(number: number) => ostrichEggHandler(number)}
      />
      <PuestosFeria
        GLTF={localAll}
        bakedAssetsTexture1={bakedAssetsTexture1}
        bakedAssetsTexture2={bakedAssetsTexture2}
        bakedAssetsTexture3={bakedAssetsTexture3}
        openChallenges={
          props.studentId == null ? props.openChallenges : () => {}
        }
        renderShadow={() => props.renderShadow()}
      />

      {/* User dependant stuff */}
      <MejorasEnergia
        GLTF={localAll}
        bakedAssetsMaterial={bakedAssetsMaterial}
        bakedAssetsMaterialH2={bakedAssetsMaterialH2}
        studentId={props.studentId}
        renderShadow={() => props.renderShadow()}
      />
      <MejorasCasas
        GLTF={localAll}
        bakedAssetsMaterial={bakedAssetsMaterial}
        studentId={props.studentId}
        funkyMode={funkyMode}
        houseMains={(position: number) => props.houseMains(position)}
        renderShadow={() => props.renderShadow()}
      />

      {hasMaterialUpgrade() && (
        <MejoraCaminos
          funkyMode={funkyMode}
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {!hasMaterialUpgrade() && (
        <Caminos
          funkyMode={funkyMode}
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      <Selection>
        <EffectComposer multisampling={8} autoClear={false}>
          <Outline
            blur
            visibleEdgeColor={0xffffff}
            edgeStrength={100}
            width={hovered ? 1000 : 1500}
          />
        </EffectComposer>
        <Select enabled={true}>
          <StaticClickableAssets
            toggleMap={() => {
              props.toggleMap();
              hideInfoBar();
            }}
            openLab={props.studentId == null ? props.openLab : () => {}}
            openEnergyLab={
              props.studentId == null ? props.openEnergyLab : () => {}
            }
            openWorkshop={
              props.studentId == null ? props.openWorkshop : () => {}
            }
            openHome={props.studentId == null ? props.openHome : () => {}}
            openChallenges={
              props.studentId == null ? props.openChallenges : () => {}
            }
            onPointerOver={() => hover(true)}
            onPointerOut={() => hover(false)}
            GLTF={localAll}
            bakedAssetsMaterial={bakedAssetsMaterial}
            renderShadow={() => props.renderShadow()}
            renderAnimation={() => props.renderAnimation()}
            tutorial={props.tutorial}
          />
        </Select>
      </Selection>

      {/* Changes depending on location */}

      {/* Grassland */}

      {props.type == "grassland" && (
        <ModelGrassland
          funkyMode={funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "grassland" && (
        <StaticAssetsGrassland
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          setFunkyMode={() => setFunkyMode(true)}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "grassland" && hasReforestationUpgrade() && (
        <MejoraVegetacionGrassland
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Jungle */}

      {props.type == "jungle" && (
        <ModelJungle
          funkyMode={funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "jungle" && (
        <StaticAssetsJungle
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          setFunkyMode={() => setFunkyMode(true)}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "jungle" && hasReforestationUpgrade() && (
        <MejoraVegetacionJungle
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Forest */}

      {props.type == "forest" && (
        <ModelGrassland
          funkyMode={funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "forest" && (
        <StaticAssetsGrassland
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          setFunkyMode={() => setFunkyMode(true)}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "forest" && hasReforestationUpgrade() && (
        <MejoraVegetacionGrassland
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Beach */}

      {props.type == "beach" && (
        <ModelBeach
          funkyMode={funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "beach" && (
        <StaticAssetsBeach
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          setFunkyMode={() => setFunkyMode(true)}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "beach" && hasReforestationUpgrade() && (
        <MejoraVegetacionBeach
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Savannah */}

      {props.type == "savannah" && (
        <ModelSavannah
          funkyMode={funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "savannah" && (
        <StaticAssetsSavannah
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          setFunkyMode={() => setFunkyMode(true)}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "savannah" && hasReforestationUpgrade() && (
        <MejoraVegetacionSavannah
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Desert */}

      {props.type == "desert" && (
        <ModelDesert
          funkyMode={funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "desert" && (
        <StaticAssetsDesert
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          setFunkyMode={() => setFunkyMode(true)}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "desert" && hasReforestationUpgrade() && (
        <MejoraVegetacionDesert
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Snow */}

      {props.type == "snow" && (
        <ModelSnow
          funkyMode={funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {/* {props.type == "snow" && <Pingüino />} */}
      {/* {props.type == "snow" && <ArcticFox />} */}
      {props.type == "snow" && (
        <StaticAssetsSnow
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          setFunkyMode={() => setFunkyMode(true)}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "snow" && hasReforestationUpgrade() && (
        <MejoraVegetacionSnow
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Swamp */}

      {props.type == "swamp" && (
        <ModelSwamp
          funkyMode={funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "swamp" && (
        <StaticAssetsSwamp
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          setFunkyMode={() => setFunkyMode(true)}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "swamp" && hasReforestationUpgrade() && (
        <MejoraVegetacionSwamp
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Mountain */}

      {props.type == "mountain" && (
        <ModelMountain
          funkyMode={funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "mountain" && (
        <StaticAssetsMountain
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          setFunkyMode={() => setFunkyMode(true)}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "mountain" && hasReforestationUpgrade() && (
        <MejoraVegetacionMountain
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}

      {/* Volcano */}

      {props.type == "volcano" && (
        <ModelVolcano
          funkyMode={funkyMode}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "volcano" && (
        <StaticAssetsVolcano
          GLTF={localAll}
          bakedAssetsMaterial={bakedAssetsMaterial}
          setFunkyMode={() => setFunkyMode(true)}
          renderShadow={() => props.renderShadow()}
        />
      )}
      {props.type == "volcano" && hasReforestationUpgrade() && (
        <MejoraVegetacionVolcano
          bakedAssetsMaterial={bakedAssetsMaterial}
          renderShadow={() => props.renderShadow()}
        />
      )}
    </>
  );
};
useGLTF.preload(
  "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Models/localMap/Local_ALL.gltf"
);

export default LocalMap;
