import { useState, useEffect } from "react";
import "./ExercisePreview.css";

import ChooseManyEdit from "../exercise_templates/ChooseManyEdit";
import ChooseOneEdit from "../exercise_templates/ChooseOneEdit";
import DefinitionEdit from "../exercise_templates/DefinitionEdit";
import TheoryEdit from "../exercise_templates/TheoryEdit";
import TrueFalseEdit from "../exercise_templates/TrueFalseEdit";
import WriteAnswersEdit from "../exercise_templates/WriteAnswersEdit";
import DragDropListEdit from "../exercise_templates/DragDropListEdit";
import DragDropTwoListsEdit from "../exercise_templates/DragDropTwoListsEdit";
import DragDropOptionsEdit from "../exercise_templates/DragDropOptionsEdit";
import FillInTheGapsEdit from "../exercise_templates/FillInTheGapsEdit";
import MediaEdit from "../exercise_templates/MediaEdit";
import BackArrow from "../../../../assets/exercises/back_arrow";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import ExerciseSelectorPage from "./exerciseSelectorPage";
import DragDropArrowsEdit from "../exercise_templates/DragDropArrowsEdit";
import { CourseType } from "../../../../_newapios/content/course";
import { ExerciseType } from "../../../../_newapios/content/unit";
import {
  CreateExerciseAPI,
  DeleteExerciseAPI,
  UpdateExerciseAPI,
} from "../../../../_newapios/content/exercise";
import { Input } from "@chakra-ui/react";
import { useAppSelector } from "../../../../hooks/hooks";

const ExercisePreviewExperiment = (props: {
  subject: CourseType;
  language: string;
  experiment_id: string | null;
  exercises: ExerciseType[];
  backHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const [selectedExercise, setSelectedExercise] = useState(0);
  const [exercises, setExercises] = useState(props.exercises);
  const [openExerciseSelector, setOpenExerciseSelector] = useState(false);

  const [imageUrl, setImageUrl] = useState("");

  const teacherID = useAppSelector((state) => state.login.id);

  useEffect(() => {
    setExercises(props.exercises);
  }, [props.exercises]);

  useEffect(() => {
    if (
      exercises.find(
        (exercise) => exercise.priority_index === selectedExercise
      ) === undefined
    ) {
      setOpenExerciseSelector(true);
    }
  }, [exercises, selectedExercise]);

  const normalizePriorityIndex = (
    exercises: ExerciseType[],
    new_exercise_index: number
  ) => {
    const newExerciseIndex = new_exercise_index + 0.5;
    const priorityIndexArray = exercises.map((exercise, index) => {
      return exercise.priority_index;
    });
    priorityIndexArray.push(newExerciseIndex);
    priorityIndexArray.sort();

    const newPriorityIndexArray = priorityIndexArray.map(
      (priorityIndex, index) => {
        return index;
      }
    );
    return newPriorityIndexArray;
  };

  const navbarClickHandler = (exerciseIndex: number) => {
    setSelectedExercise(exerciseIndex);
    setOpenExerciseSelector(false);
  };

  const createExerciseHandler = async (
    exerciseType:
      | "theory"
      | "truefalse"
      | "choosemany"
      | "chooseone"
      | "dragdropoptions"
      | "fillinthegaps"
      | "writeanswers"
      | "dragdroparrows"
      | "dragdroplist"
      | "dragdroptwolists"
      | "definition"
      | "media"
  ) => {
    var tempExercises = [...exercises];

    const priorityIndexArray = normalizePriorityIndex(
      tempExercises,
      selectedExercise
    );
    console.log(tempExercises.length, selectedExercise);

    CreateExerciseAPI(
      props.language,
      exerciseType,
      selectedExercise,
      priorityIndexArray,
      null,
      props.experiment_id
    ).then((data) => {
      setExercises(data.exercises);
      setSelectedExercise(selectedExercise);
      setOpenExerciseSelector(false);
    });
  };

  const deletePageHandler = (e: any) => {
    e.stopPropagation();

    const exercise = exercises.find(
      (exercise) => exercise.priority_index === selectedExercise
    ) as ExerciseType;
    if (exercise == null) return;

    if (window.confirm("¿Borrar esta pantalla?")) {
      DeleteExerciseAPI(
        exercise.id,
        selectedExercise,
        null,
        props.experiment_id
      ).then((data) => {
        setExercises(data.exercises);
      });
    }
  };

  const updateExerciseHandler = async (new_exercise_data: ExerciseType) => {
    var tempExercises = [...exercises];
    const newExercise = await UpdateExerciseAPI(
      new_exercise_data.id,
      new_exercise_data
    );
    tempExercises[
      tempExercises.findIndex((e) => e.id === new_exercise_data.id)
    ] = newExercise;
    setExercises(tempExercises);
  };

  console.log(exercises);

  const renderExercise = () => {
    const exercise = exercises.find(
      (exercise) => exercise.priority_index === selectedExercise
    ) as ExerciseType;
    if (exercise == null || exercise.text == null) return;
    switch (exercise.type.toLowerCase()) {
      case "choosemany":
        return (
          <ChooseManyEdit
            selectedExercise={selectedExercise}
            exercise={exercise}
            updateChooseMany={(newChooseMany: ExerciseType) =>
              updateExerciseHandler(newChooseMany)
            }
          />
        );

      case "chooseone":
        return (
          <ChooseOneEdit
            selectedExercise={selectedExercise}
            exercise={exercise}
            updateChooseOne={(newChooseOne: ExerciseType) =>
              updateExerciseHandler(newChooseOne)
            }
          />
        );

      case "definition":
        return (
          <DefinitionEdit
            selectedExercise={selectedExercise}
            exercise={exercise}
            updateDefinition={(newDefinition: ExerciseType) =>
              updateExerciseHandler(newDefinition)
            }
          />
        );

      case "theory":
        return (
          <TheoryEdit
            selectedExercise={selectedExercise}
            exercise={exercise}
            updateTheory={(newTheory: ExerciseType) =>
              updateExerciseHandler(newTheory)
            }
          />
        );

      case "truefalse":
        return (
          <TrueFalseEdit
            exercise={exercise}
            selectedExercise={selectedExercise}
            updateTrueFalse={(newTrueFalse: ExerciseType) =>
              updateExerciseHandler(newTrueFalse)
            }
          />
        );

      case "writeanswers":
        return (
          <WriteAnswersEdit
            selectedExercise={selectedExercise}
            type={"experiment"}
            exercise={exercise}
            x={Math.random()}
            updateWriteAnswers={(newWriteAnswers: ExerciseType) =>
              updateExerciseHandler(newWriteAnswers)
            }
          />
        );

      case "dragdroplist":
        return (
          <DragDropListEdit
            selectedExercise={selectedExercise}
            exercise={exercise}
            updateDragDropList={(newDragDropList: ExerciseType) =>
              updateExerciseHandler(newDragDropList)
            }
          />
        );

      case "dragdroptwolists":
        return (
          <DragDropTwoListsEdit
            selectedExercise={selectedExercise}
            exercise={exercise}
            updateDragDropTwoLists={(newDragDropTwoLists: ExerciseType) =>
              updateExerciseHandler(newDragDropTwoLists)
            }
          />
        );

      case "dragdroparrows":
        return (
          <DragDropArrowsEdit
            selectedExercise={selectedExercise}
            exercise={exercise}
            updateDragDropArrows={(newDragDropArrows: ExerciseType) =>
              updateExerciseHandler(newDragDropArrows)
            }
          />
        );

      case "dragdropoptions":
        return (
          <DragDropOptionsEdit
            selectedExercise={selectedExercise}
            exercise={exercise}
            updateDragDropOptions={(newDragDropOptions: ExerciseType) =>
              updateExerciseHandler(newDragDropOptions)
            }
          />
        );

      case "fillinthegaps":
        return (
          <FillInTheGapsEdit
            selectedExercise={selectedExercise}
            exercise={exercise}
            updateFillInTheGaps={(newFillInTheGaps: ExerciseType) =>
              updateExerciseHandler(newFillInTheGaps)
            }
          />
        );

      case "media":
        return (
          <MediaEdit
            selectedExercise={selectedExercise}
            exercise={exercise}
            updateMedia={(newMedia: ExerciseType) =>
              updateExerciseHandler(newMedia)
            }
          />
        );

      default:
        console.log("Error?");
        return <></>;
    }
  };



  const experimentNavbar = () => {
    const experimentExercises = [1, 1, 1, 1];
    console.log(selectedExercise);
    return (
      <div className="exercise_preview_navbar_container_experiment">
        {/* render back button */}
        <div
          className="navbar_element navbar_back_icon"
          onClick={() => props.backHandler()}
        >
          <motion.div
            className="center_container"
            whileHover={{ scale: 1.4 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <BackArrow color={props.subject.color} size={"45"} />
          </motion.div>
        </div>

        {/* render levels */}
        <ul
          className="navbar_level_container"
          style={{
            gridTemplateColumns:
              "repeat(" + experimentExercises?.length + ", 1fr)",
            listStyleType: "none",
          }}
        >
          {experimentExercises.map((exercise, exerciseIndex) => {
            return (
              <motion.li
                className={
                  "navbar_element navbar_level" +
                  (selectedExercise == exerciseIndex ? " selected" : "")
                }
                layout
                style={{
                  background:
                    props.subject.color +
                    (selectedExercise == exerciseIndex ? "80" : "20"),
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                onClick={() => {
                  navbarClickHandler(exerciseIndex);
                }}
                animate={
                  selectedExercise == exerciseIndex
                    ? {
                      backgroundColor: props.subject.color + "80",
                    }
                    : {
                      backgroundColor: props.subject.color + "20",
                      transition: { duration: 1.5 },
                    }
                }
              >
                <div className="center_container">
                  <motion.div
                    className={
                      selectedExercise == exerciseIndex
                        ? "navbar_level_point_now"
                        : "navbar_level_point"
                    }
                    style={{
                      backgroundColor:
                        selectedExercise == exerciseIndex
                          ? props.subject.color
                          : "#D9D9D9",
                    }}
                    whileHover={{
                      scale: 1.02,
                      boxShadow:
                        selectedExercise == exerciseIndex
                          ? "0px 0px 10px 1px " + props.subject.color + "40"
                          : "0px 0px 10px 1px " + props.subject.color + "20",
                    }}
                  ></motion.div>
                </div>
              </motion.li>
            );
          })}
        </ul>
      </div>
    );
  };

  if (openExerciseSelector) {
    return (
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
        }}
      >
        {experimentNavbar()}
        <ExerciseSelectorPage
          updateScreen={(
            exercise_type:
              | "theory"
              | "truefalse"
              | "choosemany"
              | "chooseone"
              | "dragdropoptions"
              | "fillinthegaps"
              | "writeanswers"
              | "dragdroparrows"
              | "dragdroplist"
              | "dragdroptwolists"
              | "definition"
              | "media"
          ) => createExerciseHandler(exercise_type)}
          type={"experiment"}
        />
      </div>
    );
  }

  if (exercises == null) {
    return <div></div>;
  }

  return (
    <div
      className="exercise_preview_main"
      style={{
        boxShadow: "0px 0px 10px 2px " + props.subject.color + "20",
      }}
    >
      {experimentNavbar()}

      <div>{renderExercise()}</div>

      <motion.div
        className="delete_button"
        onClick={(e) => {
          deletePageHandler(e);
        }}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #F0684F",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_small_bold">{t("delete_exercise")}</div>
        </div>
      </motion.div>
    </div>
  );
};
export default ExercisePreviewExperiment;
