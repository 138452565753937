import { ImageType, AnimationType } from "../_newapios/content/unit";
import { BiomeType } from "../_newapios/gamecontent/plots";

// Convert BiomeType from string to number
export const getBiomeTypePosition = (type: BiomeType): number => {
  const biomeTypes: BiomeType[] = [
    "swamp",
    "desert",
    "savannah",
    "grassland",
    "volcano",
    "beach",
    "jungle",
    "mountain",
    "snow",
  ];
  return biomeTypes.indexOf(type);
};
// Convert BiomeType from string to number
export const getBiomeFromNumber = (index: number): BiomeType => {
  const biomeTypes: BiomeType[] = [
    "swamp",
    "desert",
    "savannah",
    "grassland",
    "volcano",
    "beach",
    "jungle",
    "mountain",
    "snow",
  ];
  return biomeTypes[index];
};
