import "./BusinessView.css";
import { useState, useEffect } from "react";
import {
  BusinessTeacherType,
  GetAllLoginsAPI,
  GetAllTeachersAPI,
  LoginsType,
} from "../../../_newapios/user/teacher";
import { LazyMotion, m } from "framer-motion";
import Loading from "../../loading_screen/LoadingDots.tsx";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import {
  BusinessSchoolType,
  CreateSchoolAPI,
  GetAllSchoolsAPI,
  SchoolType,
} from "../../../_newapios/user/school.ts";

import Plus from "../../../assets/professor/plus.tsx";
import Close from "../../../assets/professor/close.tsx";
import AccountSchool from "../../../assets/professor/account-school.tsx";
import AccountCheck from "../../../assets/professor/account-check.tsx";
import BookOpen from "../../../assets/professor/book-open-blank-variant-outline.tsx";
import { Input } from "@chakra-ui/react";

export type viewTypes = "logins" | "licenses" | "teachers" | "schools";

const BusinessView = ({ ...props }) => {
  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const [logins, setLogins] = useState<LoginsType[]>([]);
  const [teachers, setTeachers] = useState<BusinessTeacherType[]>([]);
  const [selectedTeacher, setSelectedTeacher] =
    useState<BusinessTeacherType | null>(null);
  const [schools, setSchools] = useState<BusinessSchoolType[]>([]);
  const [selectedSchool, setSelectedSchool] =
    useState<BusinessSchoolType | null>(null);
  const [view, setView] = useState<string>("loading");
  const [openCreateSchool, setOpenCreateSchool] = useState<boolean>(false);
  const [newSchool, setNewSchool] = useState<string>("");
  const [newSchoolError, setNewSchoolError] = useState<boolean>(false);

  useEffect(() => {
    setView("loading");
    loadData();
  }, []);

  const loadData = async () => {
    const promises = [
      GetAllTeachersAPI().then((res) => setTeachers(res)),
      GetAllLoginsAPI().then((res) => setLogins(res)),
      GetAllSchoolsAPI().then((res) => setSchools(res)),
    ];
    Promise.all(promises).then((res) => {
      setView("teachers");
    });
  };

  const calcSchoolLastLogin = (school: BusinessSchoolType) => {
    let timestamp =
      school.teachers.length > 0
        ? school.teachers[0].back_office_logins.length > 0
          ? school.teachers[0].back_office_logins[0].timestamp
          : ""
        : "";
    let lastLogin = timestamp !== "" ? new Date(timestamp) : null;
    if (lastLogin === null) {
      return "Nunca";
    }
    school.teachers.forEach((teacher) => {
      teacher.back_office_logins.forEach((login) => {
        const date = new Date(login.timestamp);
        if (date > (lastLogin as Date)) {
          lastLogin = date;
        }
      });
    });

    return lastLogin.toLocaleDateString();
  };

  const newSchoolNameHandler = (e: any) => {
    setNewSchool(e.target.value);
  };

  const createSchoolHandler = async () => {
    if (newSchool !== "") {
      console.log("Creating school with name: " + newSchool);
      const status = await CreateSchoolAPI(newSchool);
      console.log("Status: " + status);
      if (status === 409) {
        setNewSchoolError(true);
        return;
      }
      if (status !== 200) {
        console.log("Error creating school");
        return;
      }
      setNewSchool("");
      setOpenCreateSchool(false);
      loadData();
    }
  };

  const renderCreateSchoolButton = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className="business_view_create_school"
          whileHover={{ scale: 1.1 }}
          onClick={() => setOpenCreateSchool(true)}
        >
          <div className="text_black_small_bold">Crear escuela</div>
        </m.div>
      </LazyMotion>
    );
  };

  const renderLegends = () => {
    return (
      <div className="business_view_legends">
        <div className="business_view_legend">
          <AccountCheck size={"20px"} color={"grey"} />
          <div className="text_black_very_small">Cuenta verificada</div>
        </div>
        <div className="business_view_legend">
          <Plus size={"20px"} color={"grey"} />
          <div className="text_black_very_small">Clase creada</div>
        </div>
        <div className="business_view_legend">
          <AccountSchool size={"20px"} color={"grey"} />
          <div className="text_black_very_small">Estudiantes</div>
        </div>
        <div className="business_view_legend">
          <BookOpen size={"20px"} color={"grey"} />
          <div className="text_black_very_small">Situaciones completadas</div>
        </div>
      </div>
    );
  };

  const renderLogins = (teacher: BusinessTeacherType | null = null) => {
    return logins
      .filter((login) =>
        teacher !== null ? login.teacher.id === teacher.id : true
      )
      .map((login) => {
        const date = new Date(login.timestamp);
        return (
          <div key={login.id} className="business_view_login">
            <div className="text_black_small_bold">
              {login.teacher.fullname}
            </div>
            <div className="text_black_small">{date.toLocaleTimeString()}</div>
            <div className="text_black_small">{date.toLocaleDateString()}</div>
          </div>
        );
      });
  };

  const renderTeachers = (school: BusinessSchoolType | null = null) => {
    return teachers
      .filter((teacher) =>
        school !== null ? teacher.school.id === school.id : true
      )
      .map((teacher) => {
        const lastLogin = new Date(teacher.back_office_logins[0]?.timestamp);
        const now = new Date();
        const dropped =
          now.getTime() - lastLogin.getTime() > 1000 * 60 * 60 * 24 * 25;
        const nurturing =
          now.getTime() - lastLogin.getTime() > 1000 * 60 * 60 * 24 * 90;

        const verified = teacher.verification_code === null;
        const classCreated = teacher.classes.length > 0;
        const hasStudents = teacher.classes.some(
          (cl) => cl.students.length > 1
        );
        const hasCompletedScenarios = teacher.classes.some((cl) =>
          cl.students.some((st) => st._count.scenario_progress > 1)
        );

        return (
          <LazyMotion features={loadFeatures} key={teacher.id}>
            <m.div
              key={teacher.id}
              className="business_view_teacher"
              onClick={() => {
                setSelectedTeacher(teacher);
                setView("logins");
              }}
              style={{
                backgroundColor: nurturing
                  ? "#FA5F55"
                  : dropped
                  ? "#FFFF8F"
                  : "",
              }}
              whileHover={{ scale: 1.01 }}
              whileInView={{
                boxShadow: nurturing
                  ? "0px 0px 5px 0px #FA5F55"
                  : dropped
                  ? "0px 0px 5px 0px #FFFF8F"
                  : "",
              }}
            >
              <div className="text_black_small_bold">{teacher.fullname}</div>
              <div className="business_view_teacher_icons">
                <AccountCheck
                  size={"20px"}
                  color={verified ? "#6EE6A7" : "grey"}
                />
                <Plus size={"20px"} color={classCreated ? "#6EE6A7" : "grey"} />
                <AccountSchool
                  size={"20px"}
                  color={hasStudents ? "#6EE6A7" : "grey"}
                />
                <BookOpen
                  size={"20px"}
                  color={hasCompletedScenarios ? "#6EE6A7" : "grey"}
                />
              </div>
              <div className="text_black_small">{teacher.school.name}</div>
              <div className="text_black_small">
                {lastLogin.toLocaleDateString()}
              </div>
            </m.div>
          </LazyMotion>
        );
      });
  };

  const renderSchools = () => {
    return schools.map((school) => {
      return (
        <LazyMotion features={loadFeatures} key={school.id}>
          <m.div
            key={school.id}
            className="business_view_teacher"
            onClick={() => {
              setSelectedSchool(school);
              setView("teachers");
            }}
            whileHover={{ scale: 1.01 }}
          >
            <div className="text_black_small_bold">{school.name}</div>
            <div className="text_black_small">
              {school.teachers.length} teachers
            </div>
            <div className="text_black_small">Pin: {school.pin}</div>
            <div className="text_black_small">
              {calcSchoolLastLogin(school)}
            </div>
          </m.div>
        </LazyMotion>
      );
    });
  };

  const renderButtons = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div className="business_view_buttons">
          {/* <div className="business_view_button" onClick={() => setView("licenses")}>Licenses</div> */}
          <m.div
            className="business_view_button"
            onClick={() => {
              setView("teachers");
              setSelectedTeacher(null);
              setSelectedSchool(null);
            }}
            whileHover={{
              scale: 1.1,
            }}
            style={{ backgroundColor: view === "teachers" ? "#5090F0" : "" }}
          >
            <div className="text_black_small_bold">Teachers</div>
          </m.div>
          <m.div
            className="business_view_button"
            onClick={() => {
              setView("schools");
              setSelectedTeacher(null);
              setSelectedSchool(null);
            }}
            whileHover={{
              scale: 1.1,
            }}
            style={{ backgroundColor: view === "schools" ? "#5090F0" : "" }}
          >
            <div className="text_black_small_bold">Schools</div>
          </m.div>
          <m.div
            className="business_view_button"
            onClick={() => {
              setView("logins");
              setSelectedTeacher(null);
              setSelectedSchool(null);
            }}
            whileHover={{ scale: 1.1 }}
            style={{ backgroundColor: view === "logins" ? "#5090F0" : "" }}
          >
            <div className="text_black_small_bold">Logins</div>
          </m.div>
        </div>
      </LazyMotion>
    );
  };

  const renderCreateSchool = () => {
    return (
      <div className="business_view_create_school_background">
        <LazyMotion features={loadFeatures}>
          <div className="business_view_create_school_container">
            <m.div
              className="business_view_create_school_close"
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300, damping: 15 }}
              onClick={() => setOpenCreateSchool(false)}
            >
              <Close size={"30px"} color={"#5090F0"} />
            </m.div>
            <div className="center_container">
              <Input
                variant="outline"
                w={"50%"}
                bgColor={"#E2EEFF"}
                as={m.input}
                whileHover={{ scale: 1.05 }}
                boxShadow={"0px 0px 5px 2px #5090F0"}
                placeholder="Nombre de la escuela"
                onChange={newSchoolNameHandler}
                value={newSchool}
                isInvalid={newSchoolError}
              />
            </div>
            <div className="center_container">
              <m.div
                className="business_view_create_school_button"
                onClick={() => createSchoolHandler()}
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 300, damping: 15 }}
              >
                Crear
              </m.div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  return (
    <div className="business_view_main">
      <div className="business_view_header">
        {renderCreateSchoolButton()}
        <img
          src="https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/ZynergicLogo.png"
          alt="logo"
          style={{
            position: "relative",
            height: "90%",
            borderRadius: "5px",
          }}
        />
        <div className="text_black_big_bold">Viva Zynergic</div>
        {renderLegends()}
      </div>
      {renderButtons()}
      {openCreateSchool && renderCreateSchool()}
      <div className="business_view_content">
        {view === "logins" && (
          <div className="business_view_logins">
            {selectedTeacher && (
              <div
                className="business_view_teacher_selected"
                onClick={() => {
                  setView("teachers");
                  setSelectedTeacher(null);
                }}
              >
                {<BackArrow color={"black"} size={"35px"} />}
                <div className="text_black_small_bold">
                  {selectedTeacher.fullname}
                </div>
                <div className="text_black_small">
                  {selectedTeacher.username}
                </div>
                <div className="text_black_small">
                  {selectedTeacher.school.name}
                </div>
              </div>
            )}
            {renderLogins(selectedTeacher)}
          </div>
        )}
        {view === "licenses" && <div className="business_view_teachers"></div>}
        {view === "teachers" && (
          <div className="business_view_teachers">
            {selectedSchool && (
              <div
                className="business_view_teacher_selected"
                onClick={() => {
                  setView("schools");
                  setSelectedSchool(null);
                }}
              >
                {<BackArrow color={"black"} size={"35px"} />}
                <div className="text_black_small_bold">
                  {selectedSchool.name}
                </div>
                <div className="text_black_small">
                  Pin: {selectedSchool.pin}
                </div>
              </div>
            )}
            {renderTeachers(selectedSchool)}
          </div>
        )}
        {view === "schools" && (
          <div className="business_view_teachers">{renderSchools()}</div>
        )}
        {view === "loading" && <Loading />}
      </div>
    </div>
  );
};

export default BusinessView;
