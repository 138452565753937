import "./DragDropArrowsEdit.css";
import { useState, useEffect } from "react";
import help from "../../../../assets/exercises/help.svg";
import plus from "../../../../assets/exercises/plus_grey.svg";
import bin from "../../../../assets/professor/bin.svg";
import { Textarea, Input } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import mediumArrow from "../../../../assets/exercises/medium_long_arrow.svg";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";

const DragDropArrowsEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateDragDropArrows: (newDragDropArrows: ExerciseType) => void;
}) => {
  const { t, i18n } = useTranslation();

  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [text, setText] = useState(props.exercise.text as string);
  const [helpText, setHelpText] = useState(props.exercise.help_text as string);
  const [labels, setLabels] = useState(props.exercise.labels as string[]);
  const [options, setOptions] = useState(props.exercise.options as string[]);
  const [displayedOptions, setDisplayedOptions] = useState<string[][]>([
    [""],
    [""],
  ]);
  const [solution, setSolution] = useState(
    props.exercise.solutions as number[]
  );
  const [saveToDB, setSaveToDB] = useState<boolean>(false);

  useEffect(() => {
    if (saveToDB) {
      var exercise = props.exercise;
      exercise.text = text;
      exercise.help_text = helpText;
      exercise.labels = labels;
      exercise.options = options;
      exercise.solutions = solution;
      props.updateDragDropArrows(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);

  useEffect(() => {
    setText(props.exercise.text as string);
    setHelpText(props.exercise.help_text as string);
    let temp_labels = props.exercise.labels as string[];
    for (let i = 0; i < 2; i++) {
      if (_.isUndefined(temp_labels[i])) {
        temp_labels[i] = "";
      }
    }
    setLabels(temp_labels);
    setOptions(props.exercise.options as string[]);
    setSolution(props.exercise.solutions as number[]);
  }, [props.exercise.text, props.selectedExercise]);

  useEffect(() => {
    orderOptions();
  }, [options]);

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
  };

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const helpTextHandler = (newHelpText: string) => {
    setHelpText(newHelpText);
  };

  const orderOptions = () => {
    var options0 = [];
    var options1 = [];
    for (var i = 0; i < options.length; i++) {
      if (solution[i] > 0) {
        options0.push(options[i]);
      } else if (solution[i] < 0) {
        options1.push(options[i]);
      }
    }
    setDisplayedOptions([options0, options1]);
  };

  const orderSolution = (oldOptions: string[], oldSolution: number[]) => {
    var newPosSolution = [];
    var newNegSolution = [];
    var newPosOptions = [];
    var newNegOptions = [];
    var newIndex = 1;
    for (let i = 0; i < options.length / 2; i++) {
      const oldSolutionIndex = oldSolution.findIndex((sol) => sol === i + 1);
      console.log("oldSolutionIndex", oldSolutionIndex);
      if (oldSolutionIndex === -1) continue;
      newPosSolution.push(newIndex);
      newPosOptions.push(oldOptions[oldSolutionIndex]);
      const oldSolutionIndexNegative = oldSolution.findIndex(
        (sol) => sol === -1 * (i + 1)
      );
      if (oldSolutionIndexNegative === -1) continue;
      newNegSolution.push(-1 * newIndex);
      newNegOptions.push(oldOptions[oldSolutionIndexNegative]);
      newIndex++;
    }
    var newSolution = newPosSolution.concat(newNegSolution);
    var newOptions = newPosOptions.concat(newNegOptions);
    console.log("opciones", oldOptions, newOptions);
    console.log("soluciones", oldSolution, newSolution);

    setOptions(newOptions);
    setSolution(newSolution);
  };

  const solutionHandler = (
    newOption: string,
    newOptionIndex: number,
    column: number
  ) => {
    console.log("newOption", newOption);
    console.log("newOptionIndex", newOptionIndex);
    console.log("column", column);
    var updatedOptions = [...displayedOptions];
    updatedOptions[column][newOptionIndex] = newOption;
    setOptions(updatedOptions[0].concat(updatedOptions[1]));
  };
  console.log("options", options);
  console.log("displayedOptions", solution);
  // old_exercise.solution[language][0].findIndex((sol) => sol === option) !== -1
  //                   ? old_exercise.solution[language][0].findIndex((sol) => sol === option) + 1
  //                   : old_exercise.solution[language][1].findIndex((sol) => sol === option) !== -1
  //                   ? (old_exercise.solution[language][1].findIndex((sol) => sol === option) + 1)*-1
  //                   : 0

  const addOptionHandler = (index: number) => {
    var updatedOptions = [...options];
    var updatedSolution = [...solution];

    updatedOptions.splice(index - 1, 0, "") &&
      updatedSolution.splice(index - 1, 0, index);
    updatedOptions.push("") && updatedSolution.push(-1 * index);
    console.log(updatedOptions, updatedSolution);

    setSolution(updatedSolution);
    setOptions(updatedOptions);
  };

  const deleteSolutionHandler = (solutionValue: number) => {
    var updatedOptions = [...options];
    var updatedSolution = [...solution];

    const deletedOptionTrueIndex0 = updatedSolution.findIndex(
      (op) => op === solutionValue
    );
    updatedOptions.splice(deletedOptionTrueIndex0, 1);
    updatedSolution.splice(deletedOptionTrueIndex0, 1);

    const deletedOptionTrueIndex1 = updatedSolution.findIndex(
      (op) => op === -1 * solutionValue
    );
    updatedOptions.splice(deletedOptionTrueIndex1, 1);
    updatedSolution.splice(deletedOptionTrueIndex1, 1);

    console.log(updatedOptions, updatedSolution);

    orderSolution(updatedOptions, updatedSolution);

    setSaveToDB(true);
  };

  const labelHandler = (newLabel: string, labelIndex: number) => {
    var newLabels = [...labels];
    newLabels[labelIndex] = newLabel;

    setLabels(newLabels);
  };

  const renderProfessor = () => {
    return (
      <div className="edit_drag_drop_arrows_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            style={{ maxHeight: "35vh" }}
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_drag_drop_arrows_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(text) && (
          <div
            className={
              text.length < 200 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={text}
              resize={"none"}
              onChange={(e) => textHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="edit_drag_drop_arrows_help_button">
        <div className="center_container">
          <motion.div
            className="write_help_text_button"
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #5090F0",
              borderRadius: "5px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            onClick={() => helpButtonHandler()}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container" style={{ fontFamily: "Causten" }}>
              {t("write_your_clue")}
            </div>
          </motion.div>
        </div>
        <div
          className="center_container"
          onClick={() => helpButtonHandler()}
          style={{ cursor: "pointer" }}
        >
          <img
            className="icon"
            style={{ maxHeight: "7vh" }}
            src={help}
            alt="help"
          />
        </div>
      </div>
    );
  };

  const renderAddButton = (index: number) => {
    if (index < 4) {
      return (
        <motion.div
          layout
          className="edit_drag_drop_arrows_add_option"
          onClick={() => addOptionHandler(index + 1)}
          whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <img src={plus} style={{ maxHeight: "5vh" }} />
          </div>
        </motion.div>
      );
    }
  };

  const renderDragDrop = () => {
    return (
      <div className="edit_drag_drop_arrows_background_edit">
        <div className="edit_drag_drop_arrows_top">
          <div className="edit_drag_drop_arrows_droppable_0">
            <div className="edit_drag_drop_arrows_label">
              <motion.div
                className="edit_drag_drop_arrows_label_container"
                style={{
                  position: "relative",
                  height: "100%",
                  width: "100%",
                  bottom: "10%",
                }}
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                <Input
                  placeholder={t("click_here_to_edit")}
                  style={{
                    width: "80%",
                    height: "100%",
                    position: "relative",
                    textAlign: "center",
                    color: "white",
                    fontFamily: "Causten",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  value={labels[0]}
                  onChange={(e) => labelHandler(e.target.value, 0)}
                  onBlur={() => setSaveToDB(true)}
                />
              </motion.div>
              <motion.div
                className="edit_drag_drop_arrows_label_container"
                style={{
                  position: "relative",
                  height: "100%",
                  width: "100%",
                  bottom: "10%",
                }}
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                <Input
                  placeholder={t("click_here_to_edit")}
                  style={{
                    width: "80%",
                    height: "100%",
                    position: "relative",
                    textAlign: "center",
                    color: "white",
                    fontFamily: "Causten",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  value={labels[1]}
                  onChange={(e) => labelHandler(e.target.value, 1)}
                  onBlur={() => setSaveToDB(true)}
                />
              </motion.div>
            </div>
            <motion.div
              layoutRoot
              className="edit_drag_drop_arrows_draggable_container"
            >
              {displayedOptions[0].map((option: string, index: number) => (
                <div className="edit_drag_drop_arrows_draggable_container_layout">
                  <Textarea
                    placeholder={t("click_here_to_edit")}
                    style={{
                      height: "100%",
                      width: "95%",
                      backgroundColor: "#FFFFFF",
                      textAlign: "center",
                      fontFamily: "Causten",
                      fontSize: option.length > 35 ? "0.8em" : "1.25em",
                      fontWeight: "bold",
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                    value={option}
                    minH="unset"
                    size={"md"}
                    resize="none"
                    as={motion.input}
                    whileHover={{
                      scale: 1.02,
                      boxShadow: "0px 0px 10px 1px #5090F0",
                    }}
                    layout
                    // onClick = {e=>{e.stopPropagation()}}
                    onChange={(e) => solutionHandler(e.target.value, index, 0)}
                    onBlur={() => setSaveToDB(true)}
                  />

                  <div
                    className="center_container"
                    style={{ height: "80%", marginLeft: "5%" }}
                  >
                    <img
                      className="icon"
                      src={mediumArrow}
                      alt="long"
                      style={{ maxWidth: "90%" }}
                    />
                  </div>

                  <Textarea
                    placeholder={t("click_here_to_edit")}
                    style={{
                      height: "100%",
                      width: "95%",
                      backgroundColor: "#FFFFFF",
                      textAlign: "center",
                      fontFamily: "Causten",
                      fontSize:
                        displayedOptions[1] &&
                          displayedOptions[1][index] &&
                          displayedOptions[1][index].length > 35
                          ? "0.8em"
                          : "1.25em",
                      fontWeight: "bold",
                      marginLeft: "5%",
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                    value={displayedOptions[1] && displayedOptions[1][index] ? displayedOptions[1][index] : ""}
                    minH="unset"
                    size={"md"}
                    resize="none"
                    as={motion.input}
                    whileHover={{
                      scale: 1.02,
                      boxShadow: "0px 0px 10px 1px #5090F0",
                    }}
                    layout
                    // onClick = {e=>{e.stopPropagation()}}
                    onChange={(e) => solutionHandler(e.target.value, index, 1)}
                    onBlur={() => setSaveToDB(true)}
                  />

                  <motion.div
                    className="edit_drag_drop_arrows_option_delete"
                    onClick={() => deleteSolutionHandler(index + 1)}
                    whileHover={{
                      scale: 1.1,
                      boxShadow: "0px 0px 10px 1px #F0684F",
                    }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    style={{
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    <div className="center_container">
                      <img src={bin} style={{ height: "3vh" }} />
                    </div>
                  </motion.div>
                </div>
              ))}
              {renderAddButton(displayedOptions[0].length)}
            </motion.div>
          </div>
        </div>
      </div>
    );
  };

  const renderHelpMenu = () => {
    return (
      <motion.div
        className="edit_drag_drop_arrows_help_menu"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        {!_.isUndefined(helpText) && (
          <div
            className={
              helpText.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={helpText}
              resize={"none"}
              onChange={(e) => helpTextHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  return (
    <div className="edit_drag_drop_arrows">
      {renderProfessor()}
      {renderTextBox()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderDragDrop()}
    </div>
  );
};

export default DragDropArrowsEdit;
