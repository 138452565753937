import "./ProfessorEvaluationStudentList.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import {
  setEvaluationMenu,
  selectedStudent,
  updateIsStudentFree,
} from "../../../../reducers/teacherSlice";
import { StudentType } from "../../../../_newapios/user/student";
import { m, LazyMotion, motion } from "framer-motion";
import {
  GetStudentCO2API,
  GetStudentPopulationAPI,
  GetStudentWattsAPI,
  GetStudentPointsAPI,
} from "../../../../_newapios/progress/student_game_metrics.ts";
import { useEffect, useState } from "react";
import _ from "lodash";
import pointsIcon from "../../../../assets/student/points_icon.svg";
import co2Icon from "../../../../assets/student/co2_icon.svg";
import kilowattsIcon from "../../../../assets/student/kilowatts_icon.svg";
import populationIcon from "../../../../assets/student/population_icon.svg";
import { useTranslation } from "react-i18next";
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Tooltip } from "@chakra-ui/react";

const ProfessorEvaluationMenu = ({ ...props }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const mainState = useAppSelector((state) => state.main);
  const students = mainState.students;
  const teacherState = useAppSelector((state) => state.teacher);
  const userLicense = teacherState.userLicense;

  const [studentsMetrics, setStudentsMetrics] = useState<{
    population: number;
    power: number;
    co2: number;
    points: number;
  }[]>([]);

  const [maxStudentId, setMaxStudentId] = useState<string>("")

  useEffect(() => {
    loadStudentsMetrics();
  }, [students]);

  if (students === null) { return }

  const loadStudentsMetrics = async () => {
    let maxStudent: {
      student_id: string,
      general_value: number
    } = {
      student_id: "",
      general_value: 0
    }
    const temp_students_metrics = await Promise.all(
      students?.map(async (student) => {
        const temp_student_metrics = await calcStudentMetrics(student)
        const generalValue = temp_student_metrics.population + temp_student_metrics.power + temp_student_metrics.co2 + temp_student_metrics.points;
        if (generalValue > maxStudent.general_value) {
          maxStudent = {
            student_id: student.id,
            general_value: generalValue
          }
        }

        return temp_student_metrics
      })
    );
    setStudentsMetrics(temp_students_metrics);
    setMaxStudentId(maxStudent.student_id);
  }
  const loadFeatures = () =>
    import("../../../../hooks/framerMotionHooks.ts").then((res) => res.default);


  const studentHandler = (student: StudentType) => {
    dispatch(setEvaluationMenu("generalInfo"));
    dispatch(selectedStudent(student));
    dispatch(updateIsStudentFree(student.id === maxStudentId)); //TODO - when we have the evaluation data for all students, we can update this
  };

  const calcStudentMetrics = async (student: StudentType) => {
    const studentPopulation = await GetStudentPopulationAPI(student.id);
    const studentPower = await GetStudentWattsAPI(student.id);
    const studentCO2 = await GetStudentCO2API(student.id);
    const studentPoints = await GetStudentPointsAPI(student.id);

    const studentPopulationValue = studentPopulation ? Math.round(studentPopulation * 100) / 100 : 0;
    const studentPowerValue = studentPower ? Math.round(studentPower * 100) / 100 : 0;
    const studentCO2Value = studentCO2 ? Math.round(studentCO2 * 100) / 100 : 0;
    const studentPointsValue = studentPoints || 0;

    return {
      population: studentPopulationValue,
      power: studentPowerValue,
      co2: studentCO2Value,
      points: studentPointsValue
    };
  }

  const renderStudentTop = () => {
    return (
      <div className="professor_evaluation_list_top">
        <div />
        <div
          className="professor_activity_progress_top_content"
          style={{
            gridTemplateColumns: "repeat(5,25%)",
            height: "100%"
          }}
        >
          <div className=" center_container">
            <img
              className="icon"
              src={pointsIcon}
              alt="icon"
              style={{ maxHeight: "2.5vh" }}
            />
            <div className="text_blue_small_bold" style={{ marginLeft: "3%" }}>
              {t("points")}
            </div>
            <Tooltip
              hasArrow
              label={t("points_info_tooltip")}
              bg={"#7D7D7D"}
              placement="top"
              sx={{
                fontFamily: "Causten",
                fontSize: "calc(5px + 0.5vw)",
                borderRadius: "5px",
              }}
              aria-label="Points Tooltip">
              <InfoOutlineIcon w={"3vh"} style={{ marginLeft: "5%" }} color={"#5090f0"} />
            </Tooltip>
          </div>
          <div className=" center_container">
            <img
              className="icon"
              src={populationIcon}
              alt="icon"
              style={{ maxHeight: "3vh" }}

            />
            <div className="text_green_small_bold" style={{ marginLeft: "3%" }}>
              {t("population").charAt(0).toUpperCase() + t("population").slice(1)}
            </div>
            <Tooltip
              hasArrow
              label={t("population_info_tooltip")}
              bg={"#7D7D7D"}
              placement="top"
              sx={{
                fontFamily: "Causten",
                fontSize: "calc(5px + 0.5vw)",
                borderRadius: "5px",
              }}
              aria-label="Population Tooltip">
              <InfoOutlineIcon w={"3vh"} style={{ marginLeft: "5%" }} color={"#6ee6a7"} />
            </Tooltip>
          </div>
          <div className=" center_container">
            <img
              className="icon"
              src={kilowattsIcon}
              alt="icon"
              style={{ maxHeight: "4vh" }}

            />
            <div className="text_yellow_small_bold" style={{ marginLeft: "3%" }}>
              {t("kilowatts").charAt(0).toUpperCase() + t("kilowatts").slice(1)}
            </div>
            <Tooltip
              hasArrow
              label={t("kilowatts_info_tooltip")}
              bg={"#7D7D7D"}
              placement="top"
              sx={{
                fontFamily: "Causten",
                fontSize: "calc(5px + 0.5vw)",
                borderRadius: "5px",
              }}
              aria-label="Kw Tooltip"
            >
              <InfoOutlineIcon w={"3vh"} style={{ marginLeft: "5%" }} color={"#fbc412"} />
            </Tooltip>
          </div>
          <div className=" center_container">
            <img
              className="icon"
              src={co2Icon}
              alt="icon"
              style={{ maxHeight: "3.5vh" }}

            />
            <div className="text_grey_small_bold" style={{ marginLeft: "3%" }}>
              {t("t_co2")}
            </div>
            <Tooltip
              hasArrow
              label={t("co2_info_tooltip")}
              bg={"#7D7D7D"}
              placement="top"
              sx={{
                fontFamily: "Causten",
                fontSize: "calc(5px + 0.5vw)",
                borderRadius: "5px",
                maxWidth: "19vw"
              }}
              aria-label="Co2 Tooltip">
              <InfoOutlineIcon w={"3vh"} style={{ marginLeft: "5%" }} color={"#b9b9b9"} />
            </Tooltip>
          </div>

        </div>
      </div>
    );
  }

  const renderStudent = (student: StudentType, studentIndex: number) => {

    return (<motion.div
      className="professor_evaluation_list_body_student"
      onClick={() => studentHandler(student)}
      whileHover={{
        scale: 1.01,
        boxShadow: "0px 0px 10px 1px #5090F0",
        borderRadius: "5 px",
      }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
    >
      <div className="left_container">
        <div className="professor_evaluation_student_icon">
          <div className="center_container">
            <div className="text_white_small_bold">
              {student.username.charAt(0).toUpperCase()}
            </div>
          </div>
        </div>
        <div
          className="text_black_very_small_bold"
          style={{ marginLeft: "3%" }}
        >
          {student.username}
        </div>
      </div>
      <div className="professor_evaluation_list_body_student_content">

        <div
          className="professor_evaluation_list_body_student_content_data"
          style={(userLicense?.license.name === "Free") ?
            (student.id === maxStudentId ? { filter: "blur(0px)" } : { filter: "blur(4px)" })
            : { filter: "blur(0)" }
          }>
          <div className="center_container">
            <div className="text_black_small_bold">
              {studentsMetrics[studentIndex]?.points}
            </div>
          </div>

          <div className="center_container">
            <div className="text_black_small_bold">
              {studentsMetrics[studentIndex]?.population}
            </div>
          </div>
          <div className="center_container">
            <div className="text_black_small_bold">
              {studentsMetrics[studentIndex]?.power}
            </div>
          </div>
          <div className="center_container">
            <div className="text_black_small_bold">
              {studentsMetrics[studentIndex]?.co2}
            </div>
          </div>
        </div>

      </div>

    </motion.div>
    );
  };

  const renderNoStudents = () => {
    return (
      <div className="professor_evaluation_no_students_container">
        <div className="center_container">
          <img src={"https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/nostudents_evaluation.gif"} style={{ maxHeight: "45vh" }} />
        </div>
        <div className="professor_evaluation_no_students_text">
          <div className="text_black_small_bold">
            {t("no_students_text_1")}
          </div>
          <div className="text_black_very_small" style={{ display: 'inline' }}>
            {t("no_students_text_2")}
          </div>
          <div className="left_container" style={{ display: "inline", fontSize: "calc(5px + 0.75vw)", fontFamily: "Causten", }}>
            <div style={{ color: "#000000" }}>

              {t("no_students_text_3")}

            </div>
            <m.div
              style={{
                color: "#5090f0",
                textDecoration: "underline",
                cursor: 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto'
              }}
              whileHover={{ textShadow: "0px 0px 10px 0px #5090F0" }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              onClick={() => window.open("https://www.youtube.com/watch?v=bvHiOfRvl-k", "_blank")}>
              {'"' + t("tutorial_how_students_access") + '"'}
            </m.div>
          </div>
        </div>
      </div>
    )
  };


  return (
    <div
      className="professor_evaluation_student_list">
      {students?.length > 0 && renderStudentTop()}
      {students?.length === 0 && renderNoStudents()}
      <div className="professor_evaluation_student_list_body">
        {students?.length > 0 && students?.map((student, index) => {
          return renderStudent(student, index);
        })}
      </div>
    </div>
  );
};

export default ProfessorEvaluationMenu;
