import "./Workshop.css";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import closeButton from "../../assets/student/close_button.svg";
import kilowattsIcon from "../../assets/student/kilowatts_icon.svg";
import starYellow from "../../assets/student/starYellow.svg";
import starGrey from "../../assets/student/starGrey.svg";
import circleArrowUp from "../../assets/student/circle_arrow_up.svg";
import {
  increaseTutorialStep,
  kilowatts,
  menu,
  population,
  updateMetrics,
} from "../../reducers/studentSlice";
import { Ii18nStringObject, IscreenSize } from "../../interfaces";
import { motion } from "framer-motion";
import {
  GetAllHouseUpgradesAPI,
  HouseUpgradeType,
} from "../../_newapios/gamecontent/house_upgrades";
import {
  GetStudentHouseAllAPI,
  SetStudentHouseAPI,
  StudentHouseType,
  UpdateStudentHouseAPI,
} from "../../_newapios/progress/student_house";
import { BiomeType } from "../../_newapios/gamecontent/plots.ts";
import ReactPlayer from "react-player";
import { GetStudentWattsAPI } from "../../_newapios/progress/student_game_metrics.ts";
import { getBiomeTypePosition } from "../../utils/game_content.ts";
import useSound from "use-sound";

const Workshop = (props: { biome: BiomeType }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const studentState = useAppSelector((state) => state.student);

  const mainState = useAppSelector((state) => state.main);

  const [houseSound] = useSound("../../assets/sounds/Upgrade+home+-01.wav", {
    volume: mainState.volumeSounds,
  });

  const local_biome =
    props.biome === "forest"
      ? "grassland"
      : props.biome === "mountain"
      ? "volcano"
      : props.biome;

  const biomeNumber = getBiomeTypePosition(local_biome);

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const [houses, setHouses] = useState<HouseUpgradeType[]>([]);
  const [studentHouses, setStudentBuildings] = useState<StudentHouseType[]>([]);
  const [playVideoConstruction, setPlayVideoConstruction] =
    useState<StudentHouseType | null>(null);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    loadBuildings();
    loadPurchasedBuildings();
  }, []);

  const loadMetrics = async () => {
    dispatch(updateMetrics());
  };

  const loadBuildings = async () => {
    const loaded_buildings = await GetAllHouseUpgradesAPI();

    // for (var i = 0; i < loaded_buildings.length; i++) {
    //   loaded_buildings[i].image = loaded_buildings[i].image;
    // }  What the hell was that LMAOOOO
    setHouses(loaded_buildings);
    loadMetrics();
  };

  const loadPurchasedBuildings = async () => {
    var student_progress = await GetStudentHouseAllAPI("me");
    console.log(student_progress);
    setStudentBuildings(student_progress);
  };

  const closeButtonHandler = () => {
    dispatch(menu("globalMap"));
  };

  const closeVideoHandler = () => {
    dispatch(menu("globalMap"));
    setPlayVideoConstruction(null);
  };

  const purchaseHandler = () => {
    const remainingPower = checkEnoughPower(houses[0].purchase_power);
    var house = houses.find(
      (b: HouseUpgradeType) => b.position === studentHouses.length + 1
    );
    if (remainingPower >= 0 && house != undefined) {
      houseSound();
      SetStudentHouseAPI(house.id).then((result) => {
        if (studentHouses.length === 0) {
          dispatch(increaseTutorialStep());
        }
        setPlayVideoConstruction(result);
        loadPurchasedBuildings();
        loadMetrics();
      });
    }
  };

  const upgradeHandler = (
    building: StudentHouseType,
    data: HouseUpgradeType
  ) => {
    const remainingPower = checkEnoughPower(data.level_power);
    if (remainingPower >= 0) {
      UpdateStudentHouseAPI(building.id).then((result) => {
        console.log(result);
        if (result != undefined) {
          if (result.level % 10 === 1) {
            houseSound();
          }
          loadPurchasedBuildings();
          loadMetrics();
        }
      });
    }
  };

  const evolveHandler = (
    building: StudentHouseType,
    data: HouseUpgradeType
  ) => {
    // Diferent handlers so we can put the video logic on this one

    const remainingPower = checkEnoughPower(data.purchase_power);
    if (building.level < 250 && remainingPower >= 0) {
      UpdateStudentHouseAPI(building.id).then((result) => {
        if (result != undefined) {
          setPlayVideoConstruction(result);
          loadPurchasedBuildings();
          loadMetrics();
        }
      });
    }
  };

  const checkEnoughPower = (neededPower: number) => {
    if (studentState.kilowatts >= neededPower) {
      return Math.round((studentState.kilowatts - neededPower) * 100) / 100;
    } else {
      return -1;
    }
  };

  const renderCloseButton = () => {
    return (
      <motion.div
        className="workshop_close_button"
        onClick={() => closeButtonHandler()}
        whileHover={{ scale: 1.2, rotate: 180 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
      >
        <div className="center_container">
          <img className="image" src={closeButton} alt="close" />
        </div>
      </motion.div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="workshop_title">
        <div className="left_container">
          <div className="text_white_very_big_bold">{t("workshop")}</div>
        </div>
      </div>
    );
  };

  const renderMenu = () => {
    var notPurchasedBuildings = [];
    for (var i = studentHouses.length; i < 6; i++) {
      notPurchasedBuildings.push(i);
    }

    return (
      <div className="workshop_menu">
        {studentHouses.map((building, buildingIndex) => {
          return renderPurchasedBuilding(building, buildingIndex);
        })}
        {notPurchasedBuildings.map((building, buildingIndex) => {
          return renderNotPurchasedBuilding(houses[0], buildingIndex);
        })}
      </div>
    );
  };

  const renderNotPurchasedBuilding = (
    data: HouseUpgradeType,
    buildingIndex: number
  ) => {
    return (
      <div
        key={buildingIndex}
        className="workshop_menu_building_not_purchased_container"
        style={{ opacity: buildingIndex === 0 ? undefined : "75%" }}
      >
        {data !== undefined && (
          <div className="workshop_menu_building_not_purchased">
            <div className="workshop_menu_building_not_purchased_top">
              <div className="left_container">
                <div className="text_black_medium_bold">{t("free_space")}</div>
              </div>
              <div className="workshop_menu_building_not_purchased_top_line" />
            </div>
            <div
              className="workshop_menu_building_not_purchased_bottom"
              style={{
                cursor:
                  buildingIndex === 0 &&
                  studentState.kilowatts >= data.purchase_power
                    ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto'
                    : 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto',
                opacity:
                  buildingIndex === 0 &&
                  studentState.kilowatts >= data.purchase_power
                    ? undefined
                    : "50%",
              }}
              onClick={() =>
                buildingIndex === 0 &&
                studentState.kilowatts >= data.purchase_power
                  ? purchaseHandler()
                  : null
              }
            >
              <div className="center_container">
                <div className="text_black_small_bold">
                  {t("build") + " " + t(data.name).toLowerCase()}
                </div>
              </div>
              <div className="center_container">
                <div className="text_black_super_small">
                  {t("build_details")}
                </div>
              </div>
              <div className="center_container">
                <img
                  className="image"
                  style={{ maxHeight: "80%" }}
                  src={data.image.url}
                  alt="house"
                />
              </div>
              <motion.div
                className="workshop_menu_building_not_purchased_button"
                whileHover={{ scale: buildingIndex === 0 ? 1.1 : 1 }}
                transition={{ type: "spring", stiffness: 500, damping: 30 }}
              >
                <div className="center_container" style={{ marginTop: "5%" }}>
                  <div className="text_white_very_small">{t("build")}</div>
                </div>
                <div className="workshop_menu_building_not_purchased_button_bottom">
                  <div className="right_container">
                    <img
                      className="image"
                      style={{ maxHeight: "85%" }}
                      src={kilowattsIcon}
                      alt="kw"
                    />
                  </div>
                  <div className="left_container" style={{ marginTop: "5%" }}>
                    <div className="text_white_very_small_bold">
                      {data.purchase_power + "kW"}
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderPlayVideoConstruction = () => {
    const house = houses.find(
      (b: HouseUpgradeType) => b.id === playVideoConstruction?.house_upgrade_id
    );
    console.log(house?.animation.length, biomeNumber);
    if (
      house === null ||
      house === undefined ||
      house.animation.length < biomeNumber
    )
      return null;

    let localBiomeNumber = biomeNumber;
    if (house.animation.length === 8 && localBiomeNumber === 8) {
      localBiomeNumber = 7;
    }
    return (
      <div className="workshop_video">
        <div className="center_container">
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            url={house.animation[localBiomeNumber].url}
            onError={() => closeVideoHandler()}
            onEnded={() => closeVideoHandler()}
            playing={true}
            controls={false}
          />
        </div>
      </div>
    );
  };

  const renderPurchasedBuilding = (
    building: StudentHouseType,
    buildingIndex: number
  ) => {
    const data = houses.find(
      (b: HouseUpgradeType) => b.id === building.house_upgrade_id
    );

    if (data != undefined) {
      return (
        <div key={buildingIndex} className="workshop_menu_building_purchased">
          {renderPurchasedBuildingTop(building, data)}
          {renderPurchasedBuildingBar(building, data)}
          {renderPurchasedBuildingDescription(data)}
          {renderPurchasedBuildingUpgrade(building, data)}
          {renderPurchasedBuildingPower(building)}
        </div>
      );
    }
  };

  const renderPurchasedBuildingTop = (
    building: StudentHouseType,
    data: HouseUpgradeType
  ) => {
    const buildingArea = Math.floor((data.max_level - building.level) / 10);
    var houseLevel;

    switch (buildingArea) {
      case 0:
        houseLevel = "V";
        break;
      case 1:
        houseLevel = "IV";
        break;
      case 2:
        houseLevel = "III";
        break;
      case 3:
        houseLevel = "II";
        break;
      case 4:
        houseLevel = "I";
        break;
      default:
        houseLevel = "V";
        break;
    }

    var starts = [];
    for (var i = 0; i < 5; i++) {
      if (i < data.type) {
        starts.push(true);
      } else {
        starts.push(false);
      }
    }

    return (
      <div className="workshop_menu_building_purchased_top">
        <div className="center_container">
          <img
            onClick={() => setPlayVideoConstruction(building)}
            className="image"
            style={{ maxHeight: "80%" }}
            src={data.image.url}
            alt="house"
          />
        </div>
        <div className="workshop_menu_building_purchased_top_right">
          <div className="left_container">
            <div className="text_black_medium_bold">
              {t(data.name) + " " + houseLevel}
            </div>
          </div>
          <div className="workshop_menu_building_purchased_top_right_line" />
          <div className="workshop_menu_building_purchased_top_right_bottom">
            <div className="workshop_menu_building_purchased_top_right_bottom_population">
              <div className="center_container">
                <div className="text_white_super_small_bold">
                  {building.population} {t("population")}
                </div>
              </div>
            </div>
            <div className="center_container">
              <div className="text_black_super_small">{t("score")}</div>
            </div>
            <div className="workshop_menu_building_purchased_top_right_bottom_stars">
              {starts.map((star, starIndex) => {
                return (
                  <div className="center_container" key={starIndex}>
                    <img
                      className="image"
                      src={star ? starYellow : starGrey}
                      alt="star"
                    />
                  </div>
                );
              })}
            </div>
            <div className="center_container">
              <div className="text_black_super_small">{data.type}/5</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPurchasedBuildingBar = (
    building: StudentHouseType,
    data: HouseUpgradeType
  ) => {
    return (
      <div className="workshop_menu_building_purchased_bar_background">
        <div
          className="workshop_menu_building_purchased_bar"
          style={{
            width:
              (building.level % 10 !== 0 ? (building.level % 10) * 10 : 100) +
              "%",
          }}
        />
        <div className="workshop_menu_building_purchased_bar_background_text">
          <div className="left_container">
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_white_super_small_bold"
                  : "text_white_very_small_bold"
              }
            >
              {t("level")} {building.level}
            </div>
          </div>
          <div className="right_container">
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_white_super_small_bold"
                  : "text_white_very_small_bold"
              }
            >
              {t("level")} {Math.ceil(building.level / 10) * 10}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPurchasedBuildingDescription = (data: HouseUpgradeType) => {
    return (
      <div className="center_container">
        <div className="text_black_super_small">{t(data.description)}</div>
      </div>
    );
  };

  const renderPurchasedBuildingUpgrade = (
    building: StudentHouseType,
    data: HouseUpgradeType
  ) => {
    return (
      <div className="workshop_menu_building_purchased_upgrade_background">
        {
          <motion.div
            className="workshop_menu_building_purchased_upgrade"
            style={{
              cursor:
                studentState.kilowatts >= data.level_power
                  ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto'
                  : 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto',
              opacity:
                studentState.kilowatts >= data.level_power ? undefined : "50%",
            }}
            onClick={() =>
              building.level === data.max_level
                ? evolveHandler(building, data)
                : upgradeHandler(building, data)
            }
            whileHover={{
              scale: studentState.kilowatts >= data.level_power ? 1.05 : 1,
            }}
            transition={{ type: "spring", stiffness: 500, damping: 30 }}
          >
            <div className="center_container">
              <img className="image" src={circleArrowUp} alt="arrow" />
            </div>
            <div className="workshop_menu_building_purchased_upgrade_center">
              <div className="left_container">
                <div className="text_black_very_small_bold">
                  {t("upgrade_house")}
                </div>
              </div>
              <div className="workshop_menu_building_purchased_upgrade_center_text">
                <div className="text_black_hiper_small">
                  {t("upgrade_house_details")}
                </div>
                <div
                  className="text_light_green_super_small_bold"
                  style={{ marginLeft: "0.3rem" }}
                >
                  {"+" + data.upgrade_population + " " + t("population")}
                </div>
              </div>
            </div>
            <div className="workshop_menu_building_purchased_upgrade_button">
              <div className="text_white_very_small">{t("upgrade")}</div>
              <div style={{ width: "85%", display: "flex" }}>
                <img className="image" src={kilowattsIcon} alt="kw" />
                <div className="text_white_very_small_bold">
                  {building.level === data.max_level
                    ? data.purchase_power * 2 +
                      "kW" /* Goofy ahh workaround to not load the next house upgrade */
                    : data.level_power + "kW"}
                </div>
              </div>
            </div>
          </motion.div>
        }
        {/* {building.score === 5 && building.level === data.lastLevel && (
          <div className="workshop_menu_building_purchased_upgrade_max">
            <div className="center_container">
              <img className="image" src={circleArrowUp} alt="arrow" />
            </div>
            <div className="center_container">
              <div className="text_black_small_bold">{t("upgraded_max")}</div>
            </div>
          </div>
        )} */}
      </div>
    );
  };

  const renderPurchasedBuildingPower = (building: StudentHouseType) => {
    return (
      <div className="workshop_menu_building_purchased_power">
        <div className="center_container">
          <div
            className={
              screenSize.dynamicWidth > 991
                ? "text_white_super_small_bold"
                : "text_white_super_small_bold"
            }
          >
            {t("total_power")}: {building.used_power} kW
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="workshop">
      <div className="workshop_banner_container">
        <img
          className="workshop_banner"
          src={
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/banner_workshop.svg"
          }
          alt="banner"
        />
      </div>
      <div className="workshop_background" />
      {renderCloseButton()}
      {renderTitle()}
      {renderMenu()}
      {playVideoConstruction !== null &&
        // props.biome !== "mountain" &&
        // props.biome !== "forest" &&
        renderPlayVideoConstruction()}
    </div>
  );
};

export default Workshop;
